import React from 'react';
import InfiniteCarousel from 'react-leaf-carousel';



export default function marcsCarousel(props) {
	// carousel data object
const ImgData = props.marca;
  // carusel data component
  
  function ImgComponent({ src, alt, classId,duracion }) {
	return (
	  <div className={classId} >
		<a href={"/catalog?marc=" + alt} filtro={alt}>
		<img src={src} alt={src} />
		</a>
	  </div>
	);
  }
  
  const ImgDataIterator = ImgData.map((element) => (
	<ImgComponent src={element.src} alt={element.alt} key={element.classId}/>
  ));

		return (
			<InfiniteCarousel
			breakpoints={[
			  {
				breakpoint: 500,
				settings: {
				  slidesToShow: 6,
				  slidesToScroll: 6,
				  autoCycle:true,
				  cycleInterval:5000,
				  pauseOnHover:false,
				},
			  },
			  {
				breakpoint: 768,
				settings: {
				  slidesToShow: 9,
				  slidesToScroll: 9,
				  autoCycle:true,
				  cycleInterval:5000,
				  pauseOnHover:false,
				},
			  },
			]}
			dots={true}
			showSides={true}
			sidesOpacity={0.5}
			sideSize={0.1}
			slidesToScroll={13}
			slidesToShow={13}
			scrollOnDevice={true}
			autoCycle={true}
			cycleInterval={5000}
			pauseOnHover={false}
		  >
			 {ImgDataIterator }

		  </InfiniteCarousel>
		)
	
}




