/*eslint-disable*/
import React from "react";
import { Card, Button, Col } from 'react-bootstrap';
import { faIdCard, faTags, faUser, faShoppingBasket, faTruck, faClipboardList, faBoxes, faCog, faFolderOpen, faFileAlt, faShoppingBag, faTag, faAddressBook } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Principal() {
  return (
    <>
      <div className="container  col-md-12" style={{ paddingTop: "150px" }}>
        <div className="row text-center col-md-12">
          <Col xs={12} sm={6} md={4} style={{ display: "flex", justifyContent: 'center', paddingBottom: '10px' }}>
            <Card style={{ width: '18rem' }} >
              <Card.Body>
                <Card.Title><FontAwesomeIcon icon={faTruck} style={{ height: "70px", width: "100px" }} /></Card.Title>
                <Card.Text>
                </Card.Text>
                <Button href="/dashboard/Pedidos" variant="danger" >Pedidos</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} style={{ display: "flex", justifyContent: 'center', paddingBottom: '10px' }}>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title><FontAwesomeIcon icon={faShoppingBasket} style={{ height: "70px", width: "100px" }} /></Card.Title>
                <Card.Text>
                </Card.Text>
                <Button href="/dashboard/Productos" variant="danger">Productos</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} style={{ display: "flex", justifyContent: 'center', paddingBottom: '10px' }}>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title><FontAwesomeIcon icon={faUser} style={{ height: "70px", width: "100px" }} /></Card.Title>
                <Card.Text>
                </Card.Text>
                <Button href="/dashboard/Clientes" variant="danger" >Clientes</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} style={{ display: "flex", justifyContent: 'center', paddingBottom: '10px' }}>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title><FontAwesomeIcon icon={faAddressBook} style={{ height: "70px", width: "100px" }} /></Card.Title>
                <Card.Text>
                </Card.Text>
                <Button href="/dashboard/Pedidos" variant="danger" eventKey="disabled" disabled>Proveedores</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} style={{ display: "flex", justifyContent: 'center', paddingBottom: '10px' }}>
            <Card style={{ width: '18rem' }} >
              <Card.Body>
                <Card.Title><FontAwesomeIcon icon={faIdCard} style={{ height: "70px", width: "100px" }} /></Card.Title>
                <Card.Text>
                </Card.Text>
                <Button href="/dashboard/Descuentos" variant="danger" >Descuentos</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} style={{ display: "flex", justifyContent: 'center', paddingBottom: '10px' }}>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title><FontAwesomeIcon icon={faClipboardList} style={{ height: "70px", width: "100px" }} /></Card.Title>
                <Card.Text>
                </Card.Text>
                <Button href="/dashboard/Marcas" variant="danger">  Marcas</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} style={{ display: "flex", justifyContent: 'center', paddingBottom: '10px' }}>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title><FontAwesomeIcon icon={faShoppingBag} style={{ height: "70px", width: "100px" }} /></Card.Title>
                <Card.Text>
                </Card.Text>
                <Button href="/dashboard/Categorias" variant="danger">  Categorías</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} style={{ display: "flex", justifyContent: 'center', paddingBottom: '10px' }}>
            <Card style={{ width: '18rem' }} >
              <Card.Body>
                <Card.Title><FontAwesomeIcon icon={faShoppingBag} style={{ height: "70px", width: "100px" }} /></Card.Title>
                <Card.Text>
                </Card.Text>
                <Button variant="danger" href="/dashboard/SubCategorias">  SubCategorías</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} style={{ display: "flex", justifyContent: 'center', paddingBottom: '10px' }}>
          <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title><FontAwesomeIcon icon={faFileAlt} style={{ height: "70px", width: "100px" }} /></Card.Title>
                <Card.Text>
                </Card.Text>
                <Button variant="danger" eventKey="disabled" disabled> Logs</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} style={{ display: "flex", justifyContent: 'center', paddingBottom: '10px' }}>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title><FontAwesomeIcon icon={faCog} style={{ height: "70px", width: "100px" }} /></Card.Title>
                <Card.Text>
                </Card.Text>
                <Button href="/dashboard/Letra" variant="danger" >  Letras</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={4} style={{ display: "flex", justifyContent: 'center', paddingBottom: '10px' }}>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title><FontAwesomeIcon icon={faFolderOpen} style={{ height: "70px", width: "100px" }} /></Card.Title>
                <Card.Text>
                </Card.Text>
                <Button  href="/dashboard/Multimedia" variant="danger"> Multimedia</Button>
              </Card.Body>
            </Card>
          </Col>                   
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>

    </>
  );
}
