/*eslint-disable*/
import React from "react";
import { Navbar, Nav, Dropdown, Form, Col, Button } from 'react-bootstrap';
// logo
import logo from '../../assets/img/Logo.png';
import '../../assets/css/dashboardNavbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faIdCard, faTags, faUser, faShoppingBasket, faTruck, faClipboardList, faBoxes, faCog, faFolderOpen, faFileAlt, faShoppingBag, faTag, faAddressBook, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { CartDashModal } from '../Modal/cartDashModal';

export default function DashboardNavbar() {
  const [CartmodalShow, setCartModalShow] = React.useState(false);
  const onSubmit = e => {
    localStorage.removeItem('tokenDash');

    let path = `/`;
    window.location.href = path;
  }
  let productosLS;
  let singUp;
  let pass = false;
  if (localStorage.getItem('productos') === null) {
    productosLS = [];
  }
  else {
    productosLS = JSON.parse(localStorage.getItem('productos'));
  }


  let contador = productosLS.length;

  var url = new URL(window.location);
  if(url.pathname === "/dashboard/Catalogo") {
    singUp = 1;
  }
  else {
    singUp = 0;
  }
  const handleAddChange = row => {

    localStorage.setItem('openPoPup', true);
    setCartModalShow(true);

  }
  return (
    <>
      <Navbar bg="light" expand="lg" className=" fixed-top">
        <Navbar.Brand href="/dashboard"><img className="LogoDos" src={logo} alt="Logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" >
            <Nav.Link href="/dashboard/Pedidos" className="cardMenu" ><span className="fuenteNavbar"><FontAwesomeIcon icon={faTruck} /> Pedidos</span></Nav.Link>
            <Nav.Link href="/dashboard/Productos" className="cardMenu"><span className="fuenteNavbar"><FontAwesomeIcon icon={faShoppingBasket} /> Productos</span></Nav.Link>
            <Nav.Link href="/dashboard/Clientes" className="cardMenu"><span className="fuenteNavbar"><FontAwesomeIcon icon={faUser} /> Clientes</span></Nav.Link>
            <Nav.Link href="/dashboard/Proveedores" className="cardMenu" eventKey="disabled" disabled><span className="fuenteNavbar"><FontAwesomeIcon icon={faAddressBook} /> Proveedores</span></Nav.Link>
            <Nav.Link href="/dashboard/Descuentos" className="cardMenu" ><span className="fuenteNavbar"><FontAwesomeIcon icon={faTags} /> Descuentos</span></Nav.Link>
            <Dropdown style={{ textAlign: "center", justifyContent: "center" }}>
              <Dropdown.Toggle variant="Danger">
                <span className="fuenteNavbar" ><FontAwesomeIcon icon={faCog} /> Configuración</span>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="dark" >
                <Dropdown.Item href="/dashboard/Marcas"><span ><FontAwesomeIcon icon={faClipboardList} style={{ width: "20px" }} /></span>
                  <span> </span>Marcas</Dropdown.Item>
                <Dropdown.Item href="/dashboard/Categorias"><span><FontAwesomeIcon icon={faShoppingBag} style={{ width: "20px" }} /></span>
                  <span> </span>Categorías</Dropdown.Item>
                <Dropdown.Item href="/dashboard/SubCategorias"><span><FontAwesomeIcon icon={faShoppingBag} style={{ width: "20px" }} /></span>
                  <span> </span>SubCategorías</Dropdown.Item>
                <Dropdown.Item href="/dashboard/Letras"><span><FontAwesomeIcon icon={faCog} style={{ width: "20px" }} /></span>
                  <span> </span>Letras</Dropdown.Item>
                <Dropdown.Item href="/dashboard/Multimedia" ><span><FontAwesomeIcon icon={faFolderOpen} style={{ width: "20px" }} /></span>
                  <span> </span>Multimedia</Dropdown.Item>
                <Dropdown.Item href="/dashboard/Logs" eventKey="disabled" disabled><span><FontAwesomeIcon icon={faFileAlt} style={{ width: "20px" }} /></span>
                  <span> </span>Logs</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          </Nav>
          <Nav.Item >

          </Nav.Item>
          <Col>
            <Nav.Item >
              <Form inline style={{ textAlign: "center", justifyContent: "flex-end", paddingTop: "15px" }} >
                {singUp === 0 ?
                  null :
                  <Button variant="outline-dark" style={{ marginRight: "10px", marginBottom: "5px" }} onClick={() => handleAddChange()} >
                    <FontAwesomeIcon icon={faShoppingCart} /> <span style={{ color: "green", font: "small-caption", fontFamily: 'sans-serif' }} id="counter">{contador}</span>
                  </Button>
                }
                <CartDashModal
                  show={CartmodalShow}
                  onHide={() => setCartModalShow(false)} />
                <Button variant="outline-dark" onClick={onSubmit} style={{ marginBottom: "5px", width: "140px" }} >
                  <FontAwesomeIcon icon={faSignOutAlt} /><span className="button-text"> Cerrar Sesión</span>
                </Button>
              </Form>
            </Nav.Item>
          </Col>
        </Navbar.Collapse>
      </Navbar>

    </>
  );
}

