
/*eslint-disable*/
import React, { useContext, useEffect } from "react";

import { Button,Form,Col } from 'react-bootstrap';
import { ProductModal } from '../../components/Modal/productModal';
import ProductTable from "../../components/Tables/productTable";
import ProductoState from '../../context/productos/productoContext';
import ProcessFileUploadProducts from "../../components/FileUpload/ProcessFileUploadProducts";

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlusSquare, faArrowRight } from '@fortawesome/free-solid-svg-icons';
//Table
import { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

export default function Productos() {

  const columns = [{
    dataField: 'ID',
    text: 'id',
    sort: true,
    hidden: true,
    csvExport: false
  }, {
    dataField: 'NroProducto',
    text: 'Nro Artículo',
    sort: true,
    filter: textFilter({
      placeholder: 'Nro Artículo...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "8%", textAlign: "center" };
    }
  }, {
    dataField: 'NombreProducto',
    text: 'Nombre',
    sort: true,
    filter: textFilter({
      placeholder: 'Nombre...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "9%", textAlign: "center" };
    }
  }, {
    dataField: 'Descripcion',
    text: 'Descripción',
    sort: true,
    filter: textFilter({
      placeholder: 'Descripción...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "16%", textAlign: "center" };
    }
  }, {
    dataField: 'Marca',
    text: 'Marca',
    sort: true,
    filter: textFilter({
      placeholder: 'Marca...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "8%", textAlign: "center" };
    },
    editable: false
  }, {
    dataField: 'Categoria',
    text: 'Categoria',
    sort: true,
    filter: textFilter({
      placeholder: 'Categoria...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "9%", textAlign: "center" };
    },
    editable: false
  }, {
    dataField: 'SubCategoria',
    text: 'SubCategoria',
    sort: true,
    filter: textFilter({
      placeholder: 'SubCategoria...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "9%", textAlign: "center" };
    },
    editable: false
  }, {
    dataField: 'Costo',
    text: 'Costo',
    sort: true,
    filter: textFilter({
      placeholder: 'Costo...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "8%", textAlign: "center" };
    }
  }, {
    dataField: 'Precio',
    text: 'Precio',
    sort: true,
    filter: textFilter({
      placeholder: 'Precio...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "8%", textAlign: "center" };
    }
  }, {
    dataField: 'PrecioPromocional',
    text: 'Precio Promocional',
    sort: true,
    filter: textFilter({
      placeholder: 'Precio Promocional...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "8%", textAlign: "center" };
    }
  }, {
    dataField: 'ComisionVendedor',
    text: 'Comision Vendedor',
    sort: true,
    filter: textFilter({
      placeholder: 'Comisión Vendedor...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "8%", textAlign: "center" };
    }
  }, {
    dataField: 'Stock',
    text: 'Stock',
    sort: true,
    filter: textFilter({
      placeholder: 'Stock...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "5%", textAlign: "center" };
    }
  }, {
    dataField: 'CantidadMinima',
    text: 'Cantidad Minima',
    sort: true,
    filter: textFilter({
      placeholder: 'Stock...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "5%", textAlign: "center" };
    }
  }, {
    dataField: '',
    text: '',
    formatter: eliminar,
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        var id = row.ID;

        setTimeout(() => {
          Confirm.open({
            title: 'Eliminar Producto',
            message: '¿Esta seguro de eliminar el Producto ' + row.NroProducto + '?',
            onok: () => {
              document.body.style.backgroundColor = 'blue';
            },
            registro: id
          })
        }, 0);
      }
    },
    headerStyle: () => {
      return { width: "2%" };
    },
    align: 'center',
    csvExport: false
  }
  ];

  function eliminar() {
    return (
      <a ><i className="icon" style={{ color: "black", cursor: "pointer" }} ><FontAwesomeIcon icon={faTrashAlt} /></i></a>
    );
  }

  const Confirm = {
    open(options) {
      options = Object.assign({}, {
        title: '',
        message: '',
        okText: 'Si',
        cancelText: 'Cancelar',
        onok: function () { },
        oncancel: function () { },
        registro: ''
      }, options);

      const html = `
            <div class="confirm">
                <div class="confirm__window">
                    <div class="confirm__titlebar">
                        <span class="confirm__title">${options.title}</span>
                        <button class="confirm__close">&times;</button>
                    </div>
                    <div class="confirm__content">${options.message}</div>
                    <div class="confirm__buttons">
                        <button class="confirm__button confirm__button--ok confirm__button--fill">${options.okText}</button>
                        <button class="confirm__button confirm__button--cancel">${options.cancelText}</button>
                    </div>
                </div>
            </div>
        `;

      const template = document.createElement('template');
      template.innerHTML = html;

      // Elements
      const confirmEl = template.content.querySelector('.confirm');
      const btnClose = template.content.querySelector('.confirm__close');
      const btnOk = template.content.querySelector('.confirm__button--ok');
      const btnCancel = template.content.querySelector('.confirm__button--cancel');

      confirmEl.addEventListener('click', e => {
        if (e.target === confirmEl) {
          options.oncancel();
          this._close(confirmEl);
        }
      });

      btnOk.addEventListener('click', () => {

        var objProducto = new Object();
        objProducto.id = options.registro;
        productoDelete({ objProducto });
        window.location.reload(true);

      });

      [btnCancel, btnClose].forEach(el => {
        el.addEventListener('click', () => {
          options.oncancel();
          this._close(confirmEl);
        });
      });

      document.body.appendChild(template.content);
    },

    _close(confirmEl) {
      confirmEl.classList.add('confirm--close');

      confirmEl.addEventListener('animationend', () => {
        document.body.removeChild(confirmEl);
      });
    }
  };

  const [modalShowTwo, setModalShowTwo] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const handleCategoriaChange = () => {
    var select = document.getElementById('columna');
    var idColumna = select.options[select.selectedIndex].value;
    if(idColumna == 0){
      setShow(false);
    }else{
      setShow(true);
    }
    

  }
  const productoContext = useContext(ProductoState);
  const { productos, modificaProducto, obtenerProductos, productoDelete } = productoContext;
  // Obtener proyectos cuando carga el componente
  useEffect(() => {
    obtenerProductos();
        var select = document.getElementById('letra');
  }, [modificaProducto]);

  return (
    <>
      <div data-aos="fade-down">
        <br></br>
        <div className="container col-md-12" style={{ paddingTop: '80px' }} >
          <div className="row col-md-12" style={{ alignContent: "center" }}>
            <a href="/dashboard" style={{ color: "black" }}><h6>Principal</h6></a><h6><FontAwesomeIcon icon={faArrowRight} style={{ width: "80%", paddingLeft: "10px", paddingRight: "2px" }} /></h6><h6 style={{ color: 'red' }}>Producto</h6>
          </div>
        </div>
        <div className="container col-md-12" style={{ paddingBottom: '5px' }} >
          <div className="row col-md-12" style={{ justifyContent: "end" }}>
            <Button variant="dark" onClick={() => setModalShowTwo(true)}><FontAwesomeIcon icon={faPlusSquare} /> Nuevo Producto</Button>
            <ProductModal
              show={modalShowTwo}
              onHide={() => setModalShowTwo(false)} />
          </div>
        </div>
        <div className="container col-md-12" >
          <div className="row col-md-12" >
            <ProductTable
              products={productos}
              columns={columns}
              llave={'ID'}
              indication={'No se encontraron Productos.'}
              mensajeMod={'¿Desea guardar los cambios realizados en Productos?'}
            >
            </ProductTable>
          </div>
        </div>
        <div className="container col-md-12" >
        <Form.Group as={Col} md={2}>
          <Form.Label >Actualizar:</Form.Label>
          <select className="form-control" onChange={handleCategoriaChange} id="columna">
            <option value={0}>Seleccione Columna</option>
            <option value={"Stock"}>Stock</option>
            <option value={"Costo"}>Costo</option>
            <option value={"Precio"}>Precio</option>
            <option value={"PrecioPromocional"}>Precio Promocional</option>
            <option value={"ComisionVendedor"}>Comision Vendedor</option>
          </select>
        </Form.Group>
        
        </div>
        <div className="container col-md-12" >
          <div className="row col-md-12">
            <div className="col-md-4">
              {show ? <ProcessFileUploadProducts /> : null}
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

