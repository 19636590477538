const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
const OCULTAR_ALERTA = 'OCULTAR_ALERTA';


export default (state,action) => {
    switch(action.type){
        case MOSTRAR_ALERTA:
            return{
                alerta:action.payload
            }
        case OCULTAR_ALERTA:
            return{
                alerta:null
            }
        default:
            return state;
    }
}



