import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// views
import Login from "../views/auth/Login.js";
import nuevoUsuario from "../views/auth/NuevoUsuario.js";

export default function Auth() {
  return (
    <>
      <main>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/nuevoUsuario" exact component={nuevoUsuario} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
      </main>
    </>
  );
}
