/*eslint-disable*/
import React from "react";

export default function Logs() {
  return (
    <>

<div className="container col-md-12" style={{ display: "flex", height: "100%", minHeight: "100vh" }} data-aos="fade-down">
        <div className="row col-md-12" style={{ textAlign: "center", alignContent: "center" }} >
          <div className="col-md-12">
            <h1  >Desde Logs</h1>
          </div>
        </div>
      </div>

    </>
  );
}
