/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import CategoriaContext from "../../context/categorias/categoriaContext";
import AlertaContext from "../../context/alertas/alertaContext";

export function CategoryModal(props) {

  const history = useHistory();
  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const categoriaContext = useContext(CategoriaContext);
  const {exito, mensaje, categoriaCrear } = categoriaContext;

  useEffect(() => {
    if(exito){
      location.reload();
    }
    if (mensaje) {
      mostrarAlerta(mensaje.msg, "cat");
    }
  }, [mensaje,exito, props.history]
  )

  const [categoria, guardarCategoria] = useState({
    nombre: '',
    descripcion: ''
  });

  const { nombre, descripcion } = categoria;

  const onChange = e => {
    guardarCategoria({
      ...categoria,
      [e.target.name]: e.target.value
    })
  }

  const onSubmit = e => {
    e.preventDefault();

    categoriaCrear({
      nombre, descripcion
    });
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="confirm__titlebar ">
        <h5 className="confirm__title">Nueva Categoría</h5>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:"#eeeeee"}}>
        <Form onSubmit={onSubmit}>
          
            <Form.Group as={Col} md={6} controlId="nombre">
              <Form.Label >Nombre: </Form.Label>
              <Form.Control type="text" placeholder="Ingrese Categoría..." name="nombre" value={nombre} onChange={onChange} />
            </Form.Group>
        
            <Form.Group as={Col} controlId="descripcion">
              <Form.Label >Descripción:</Form.Label>
              <Form.Control type="text" placeholder="Ingrese Descripción..." name="descripcion" value={descripcion} onChange={onChange} />
            </Form.Group>
        
          <div className="row" style={{ justifyContent: "center" }}>
            {alerta ? (<div className={`alerta ${alerta.categoria}`} style={{ backgroundColor: "red", color: "white", textAlign: "center", borderRadius: "5px", padding: "0% 2% 0% 2%" }}>{alerta.msg}</div>) : null}
          </div>
          <br></br>
          <Row className="mb-3" style={{ justifyContent: "center", textAlign: "center" }} >
            <Button className="confirm__button confirm__button--ok confirm__button--fill" variant="success" type="submit" style={{backgroundColor:"green",borderColor:"green"}} >
              Guardar
            </Button>
          </Row>
        </Form>
       
      </Modal.Body>
    </Modal>
  );
}
