import React, { useReducer } from 'react';
import descuentoReducer from './descuentoReducer';
import descuentoContext from './descuentoContext';
import clienteAxios from "../../config/axios";

const OBTENCION_EXITOSA = 'OBTENCION_EXITOSA';
const REGISTRO_ERROR = 'REGISTRO_ERROR';
const OPERACION_EXITOSA = 'OPERACION_EXITOSA';

const DescuentoState = props => {
    const initialState = {
        descuento: [],
        modificaDescuento: null,
        mensaje: '',
        exito:false
        
    }
    const [state, dispatch] = useReducer(descuentoReducer, initialState);

    const obtenerDescuentos = async registro => {
        try {
            const resultado = await clienteAxios.post('/products/getDiscount',registro);
            if (resultado.status === 200) {
                dispatch({
                    type: OBTENCION_EXITOSA,
                    payload: resultado.data.rows
                })
            }
            else {
           
            }
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }
  
    const descuentoUpdate = async registro => {
        try {

            const respuesta = await clienteAxios.post('/products/updateDiscount', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const descuentoDelete = async registro => {
        try {
            const respuesta = await clienteAxios.post('/products/deleteDiscount', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const descuentoCrear = async registro => {
        try {

            const respuesta = await clienteAxios.post('/products/createDiscount', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            });
        } catch (error) {         
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispatch({
                type:REGISTRO_ERROR,
                payload: alerta
            });
        }
    }

    return (
        <descuentoContext.Provider
            value={{
                modificaDescuento: state.modificaDescuento,
                descuento: state.descuento,
                mensaje: state.mensaje,
                exito: state.exito,
                obtenerDescuentos,
                descuentoUpdate,
                descuentoDelete,
                descuentoCrear
            }}>
            {props.children}
        </descuentoContext.Provider>
    )
}

export default DescuentoState;