import React, { useReducer } from 'react';
import marcaReducer from './marcaReducer';
import marcaContext from './marcaContext';
import clienteAxios from "../../config/axios";

const OBTENCION_EXITOSA = 'OBTENCION_EXITOSA';
const REGISTRO_ERROR = 'REGISTRO_ERROR';
const OPERACION_EXITOSA = 'OPERACION_EXITOSA';

const MarcaState = props => {
    const initialState = {
        marcas: [],
        modificaMarca: null,
        mensaje: '',
        exito:false
    }
    const [state, dispatch] = useReducer(marcaReducer, initialState);

    const obtenerMarcas = async () => {
        try {
            const resultado = await clienteAxios.post('/marcs/get');
            if (resultado.status === 200) {
                dispatch({
                    type: OBTENCION_EXITOSA,
                    payload: resultado.data.rows
                })
            }
            else {
           
            }
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const obtenerMarcasFront = async (protocolo) => {
        try {
            let resultado ;
          
                resultado = await clienteAxios.post('/marcs/getFront');

            dispatch({
                type: OBTENCION_EXITOSA,
                payload: resultado.data.rows
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const obtenerMarcasFiltro = async () => {
        try {
            console.log(window.location.href);
            const resultado = await clienteAxios.post('/marcs/getMarcsFilter');

            dispatch({
                type: OBTENCION_EXITOSA,
                payload: resultado.data.rows
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }
    

    const marcaUpdate = async registro => {
        try {

            const respuesta = await clienteAxios.post('/marcs/updateMarcs', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const marcaDelete = async registro => {
        try {
            const respuesta = await clienteAxios.post('/marcs/deleteMarcs', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const marcaCrear = async registro => {
        try {

            const respuesta = await clienteAxios.post('/marcs/insertMarcs', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            });
        } catch (error) {         
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispatch({
                type:REGISTRO_ERROR,
                payload: alerta
            });
        }
    }

    return (
        <marcaContext.Provider
            value={{
                modificaMarca: state.modificaMarca,
                marcas: state.marcas,
                mensaje: state.mensaje,
                exito: state.exito,
                obtenerMarcas,
                obtenerMarcasFront,
                marcaUpdate,
                marcaDelete,
                marcaCrear,
                obtenerMarcasFiltro
            }}>
            {props.children}
        </marcaContext.Provider>
    )
}

export default MarcaState;