import React, { useReducer } from 'react';
import subCategoriaReducer from './subCategoriaReducer';
import subCategoriaContext from './subCategoriaContext';
import clienteAxios from "../../config/axios";

const OBTENCION_EXITOSA = 'OBTENCION_EXITOSA';
const REGISTRO_ERROR = 'REGISTRO_ERROR';
const OPERACION_EXITOSA = 'OPERACION_EXITOSA';

const SubCategoriaState = props => {
    const initialState = {
        subCategorias: [],
        modificaSubCategoria: null,
        mensaje: '',
        exito: false
    }
    const [state, dispatch] = useReducer(subCategoriaReducer, initialState);

    const obtenerSubCategorias = async () => {
        try {
            const resultado = await clienteAxios.post('/subCategory/get');
            if (resultado.status === 200) {
                dispatch({
                    type: OBTENCION_EXITOSA,
                    payload: resultado.data.rows
                })
            }
            else {

            }
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const obtenerSubCategoriasSeleccionada = async registro => {
        try {
            const resultado = await clienteAxios.post('/subCategory/getSelect',registro);
            if (resultado.status === 200) {
                dispatch({
                    type: OBTENCION_EXITOSA,
                    payload: resultado.data.rows
                })
            }
            else {

            }
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const subCategoriaUpdate = async registro => {
        try {
            const respuesta = await clienteAxios.post('/subCategory/updateSubCategory', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const subCategoriaDelete = async registro => {
        try {
            const respuesta = await clienteAxios.post('/subCategory/deleteSubCategory', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const subCategoriaCrear = async registro => {
        try {

            const respuesta = await clienteAxios.post('/subCategory/insertSubCategory', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            });
        } catch (error) {
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            });
        }
    }

    return (
        <subCategoriaContext.Provider
            value={{
                modificaSubCategoria: state.modificaSubCategoria,
                subCategorias: state.subCategorias,
                mensaje: state.mensaje,
                exito: state.exito,
                obtenerSubCategorias,
                subCategoriaUpdate,
                subCategoriaDelete,
                subCategoriaCrear,
                obtenerSubCategoriasSeleccionada
            }}>
            {props.children}
        </subCategoriaContext.Provider>
    )
}

export default SubCategoriaState;