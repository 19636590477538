import React, { useReducer} from 'react';
import alertaReducer from './alertaReducer';
import alertaContext from './alertaContext';

const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
const OCULTAR_ALERTA = 'OCULTAR_ALERTA';

const AlertaState = props => {
    const initialState = {
        alerta:null
    }

    const [state, dispath] = useReducer(alertaReducer,initialState);

    const mostrarAlerta = (msg,categoria) => {
         dispath({
             type:MOSTRAR_ALERTA,
             payload: {
                 msg,
                 categoria
             }
         });

         setTimeout(() => {
             dispath({
                 type:OCULTAR_ALERTA
             })
         }, 3000);
    }

    return(
        <alertaContext.Provider
        value={{
            alerta: state.alerta,
            mostrarAlerta
        }}
        >
            {props.children}
        </alertaContext.Provider>
    )
}

export default AlertaState;