const OBTENCION_EXITOSA = 'OBTENCION_EXITOSA';
const OBTENCION_EXITOSA_DET = 'OBTENCION_EXITOSA_DET';

const REGISTRO_ERROR = 'REGISTRO_ERROR';
const OPERACION_EXITOSA = 'OPERACION_EXITOSA';
const PEDIDO_EXITOSO = 'PEDIDO_EXITOSO';
export default (state, action) => {
    switch (action.type) {
        case OBTENCION_EXITOSA:
            return {
                ...state,
                pedido: action.payload
            }
        case OBTENCION_EXITOSA_DET:
            return {
                ...state,
                pedidoDet: action.payload
            }
        case OPERACION_EXITOSA:
            return {
                ...state,
                pedido: action.payload,
                exito: true
            }
        case PEDIDO_EXITOSO:
            return {
                ...state,
                pedido: action.payload,
                exito: true
            }
        case REGISTRO_ERROR:
            return {
                ...state,
                mensaje: action.payload
            }
        default:
            return state;
    }
}


