import React, { useReducer } from 'react';
import letraReducer from './letraReducer';
import letraContext from './letraContext';
import clienteAxios from "../../config/axios";

const OBTENCION_EXITOSA = 'OBTENCION_EXITOSA';
const REGISTRO_ERROR = 'REGISTRO_ERROR';
const OPERACION_EXITOSA = 'OPERACION_EXITOSA';
const OBTENCION_EXITOSA_DISC = 'OBTENCION_EXITOSA_DISC';
const LetraState = props => {
    const initialState = {
        letras: [],
        dics: [],
        modificaLetra: null,
        mensaje: '',
        exito:false
        
    }
    const [state, dispatch] = useReducer(letraReducer, initialState);

    const obtenerLetras = async () => {
        try {
            const resultado = await clienteAxios.post('/lyrics/get');
            if (resultado.status === 200) {
                dispatch({
                    type: OBTENCION_EXITOSA,
                    payload: resultado.data.rows
                })
            }
            else {
           
            }
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const letraUpdate = async registro => {
        try {

            const respuesta = await clienteAxios.post('/lyrics/updateLyrics', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const letraDelete = async registro => {
        try {
            const respuesta = await clienteAxios.post('/lyrics/deleteLyrics', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const letraCrear = async registro => {
        try {

            const respuesta = await clienteAxios.post('/lyrics/insertLyrics', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            });
        } catch (error) {         
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispatch({
                type:REGISTRO_ERROR,
                payload: alerta
            });
        }
    }

 

    return (
        <letraContext.Provider
            value={{
                modificaLetra: state.modificaLetra,
                letras: state.letras,
                dics: state.dics,
                mensaje: state.mensaje,
                exito: state.exito,
                obtenerLetras,
                letraUpdate,
                letraDelete,
                letraCrear,
                
            }}>
            {props.children}
        </letraContext.Provider>
    )
}

export default LetraState;