/*eslint-disable*/
import React, { useContext, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Button } from 'react-bootstrap';
// es6
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

const defaultSorted = [{
  dataField: 'id',
  order: 'asc'
}];

export default (props) => {


  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = 'black';
      activeStyle.color = 'white';
      activeStyle.border = 'none';
    } else {
      activeStyle.backgroundColor = '#6c757d';
      activeStyle.color = 'white';
      activeStyle.border = 'none';
    }
    if (typeof page === 'string') {
      activeStyle.backgroundColor = '#6c757d';
      activeStyle.color = 'white';
      activeStyle.border = 'none';
    }
    return (
      <li className="page-item">
        <Button onClick={handleClick} variant="dark" style={activeStyle}>{page}</Button>
      </li>
    );
  };

  const options = {
    pageButtonRenderer
  };

  return (
    <ToolkitProvider
      keyField={props.llave}
      data={props.products}
      columns={props.columns}
      noDataIndication={props.indication}
      exportCSV={{ onlyExportFiltered: true, exportAll: false, fileName: 'Catalogo.csv' }}     
    >
      {
        props => (
          <div>
             <BootstrapTable
              {...props.baseProps}
              noDataIndication={'No se encontraron Productos.'}
              hover
              condensed
              tabIndexCell
              defaultSorted={defaultSorted}
              bootstrap4
              pagination={paginationFactory(options)}
              filter={filterFactory()}
              id={'test'}
            />
          </div>
        )
      }
    </ToolkitProvider>
  );
}
