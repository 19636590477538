import React, { useReducer} from 'react';
import productoReducer from './productoReducer';
import productoContext from './productoContext';
import clienteAxios from "../../config/axios";
import tokenAuth from "../../config/token";

const OBTENCION_EXITOSA = 'OBTENCION_EXITOSA';
const REGISTRO_ERROR = 'REGISTRO_ERROR';
const OPERACION_EXITOSA = 'OPERACION_EXITOSA';
const PEDIDO_EXITOSO = 'PEDIDO_EXITOSO';

const ProductoState = props =>{
    const initialState = {
        productos : [],
        lista : [],
        modificaProducto: null,
        mensaje: '',
        exito: false
    }
    const [state, dispatch] = useReducer(productoReducer,initialState);

    const obtenerProductos = async () => {
        try {
            const resultado = await clienteAxios.post('/products/get');

            dispatch({
                type: OBTENCION_EXITOSA,
                payload: resultado.data.rows
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }
   const productoUpdate = async registro => {
        try {
            const respuesta = await clienteAxios.post('/products/updateProducts', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const productoDelete = async registro => {
        try {
            const respuesta = await clienteAxios.post('/products/deleteProducts', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const productoCrear = async registro => {
        try {

            const respuesta = await clienteAxios.post('/products/insertProducts', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            });
        } catch (error) {
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            });
        }
    }

    const obtenerProductosFront = async () => {
        try {
            const resultado = await clienteAxios.post('/catalog/getCatalog');

            dispatch({
                type: OBTENCION_EXITOSA,
                payload: resultado.data.rows
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            console.log(error);
            
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const obtenerProductosFrontClient = async cliente => {
        try {
            const token = localStorage.getItem('token');
            if(token){
                tokenAuth(token);
            }

            const resultado = await clienteAxios.post('/catalog/getCatalogClient');

            dispatch({
                type: OBTENCION_EXITOSA,
                payload: resultado.data.rows
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            console.log(error);
            
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const obtenerProductosClient = async cliente => {
        try {
            var objSubCategoria = new Object;
            objSubCategoria.id = cliente == "0" ? null : cliente;
            const resultado = await clienteAxios.post('/catalog/getCatalogClientSim',objSubCategoria);

            dispatch({
                type: OBTENCION_EXITOSA,
                payload: resultado.data.rows
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            console.log(error);
            
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const realizarPedido = async pedido => {
        try {
            const token = localStorage.getItem('token');
            if(token){
                tokenAuth(token);
            }

            const resultado = await clienteAxios.post('/delivery/create',pedido);

            dispatch({
                type: PEDIDO_EXITOSO,
                payload: resultado.data.rows
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            console.log(error);
            
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    return(
        <productoContext.Provider
        value={{
            modificaProducto: state.modificaProducto,
            productos: state.productos,
            mensaje: state.mensaje,
            exito: state.exito,
            obtenerProductos,
            productoUpdate,
            productoDelete,
            productoCrear,
            obtenerProductosFront,
            obtenerProductosFrontClient,
            obtenerProductosClient,
            realizarPedido,
            lista: state.lista
        }}
        >
            {props.children}
        </productoContext.Provider>
    )
}


export default ProductoState;