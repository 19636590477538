import React, { useReducer } from 'react';
import authReducer from './authReducer';
import authContext from './authContext';
import clienteAxios from "../../config/axios";
import tokenAuth from "../../config/token";

const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
const REGISTRO_EXITOSO_DASH = 'REGISTRO_EXITOSO_DASH';
const REGISTRO_ERROR = 'REGISTRO_ERROR';
//const OBTENER_USUARIO = 'OBTENER_USUARIO';
//const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
const LOGIN_ERROR = 'LOGIN_ERROR';
const TOKEN_EXITOSO = 'TOKEN_EXITOSO'
//const CERRAR_SESION = 'CERRAR_SESION';
const OBTENCION_EXITOSA = 'OBTENCION_EXITOSA';
const OPERACION_EXITOSA = 'OPERACION_EXITOSA';

const AuthState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        tokenDash: localStorage.getItem('tokenDash'),
        autenticado: null,
        autenticadoDash: null,
        usuario: null,
        mensaje: null,
        clientes: [],
        exito: false
    }

    const [state, dispath] = useReducer(authReducer, initialState);

    const registrarUsuario = async datos => {
        try {

            const respuesta = await clienteAxios.post('/users/create-user', datos);

            dispath({
                type: REGISTRO_EXITOSO,
                payload: respuesta.data
            })

            usuarioAuth();
        } catch (error) {
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispath({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const authUsuario = async datos => {
        try {

            const respuesta = await clienteAxios.post('/users/auth-user', datos);

            dispath({
                type: REGISTRO_EXITOSO_DASH,
                payload: respuesta.data
            })

        } catch (error) {
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispath({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const newPassUsuario = async datos => {
        try {

            const respuesta = await clienteAxios.post('/users/new-pass-user', datos);

            dispath({
                type: REGISTRO_EXITOSO_DASH,
                payload: respuesta.data
            })

        } catch (error) {
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispath({
                type: LOGIN_ERROR,
                payload: alerta
            })
        }
    }
    //Retorna el usuario Autenticado
    const usuarioAuth = async () => {
        
     
        try {
            const respuesta = await clienteAxios.get('/users/auth');
        } catch (error) {
            dispath({ type: LOGIN_ERROR })
        }
    }

    const registrarCliente = async datos => {
        try {

            const respuesta = await clienteAxios.post('/client/create-client', datos);

            dispath({
                type: REGISTRO_EXITOSO,
                payload: respuesta.data
            })

        } catch (error) {
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispath({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const authCliente = async datos => {
        try {

            const respuesta = await clienteAxios.post('/client/auth-client', datos);
            
            dispath({
                type: REGISTRO_EXITOSO,
                payload: respuesta.data
            })

        } catch (error) {
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispath({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const valToken = async datos => {
        try {
            const token = localStorage.getItem('token');
            if(token){
                tokenAuth(token);
            }
            const respuesta = await clienteAxios.post('/client/valToken', datos);
            console.log(respuesta);

        } catch (error) {
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispath({
                type: LOGIN_ERROR,
                payload: alerta
            })
        }
    }

    const obtenerClientes = async () => {
        try {
            const resultado = await clienteAxios.post('/client/get-clients');
            if (resultado.status === 200) {
                dispath({
                    type: OBTENCION_EXITOSA,
                    payload: resultado.data.rows
                })
            }
            else {
           
            }
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispath({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const clienteDelete = async registro => {
        try {
            const respuesta = await clienteAxios.post('/client/del-clients', registro);
            dispath({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispath({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const clienteUpdate = async registro => {
        try {

            const respuesta = await clienteAxios.post('/client/upd-clients', registro);
            dispath({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispath({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    return (
        <authContext.Provider
            value={{
                token: state.token,
                tokenDash : state.tokenDash,
                autenticado: state.autenticado,
                autenticadoDash: state.autenticadoDash,
                usuario: state.usuario,
                mensaje: state.mensaje,
                registrarUsuario,
                registrarCliente,
                authUsuario,
                obtenerClientes,
                authCliente,
                clienteDelete,
                clienteUpdate,
                valToken,
                newPassUsuario,
                exito: state.exito,
                clientes: state.clientes
            }}
        >
            {props.children}
        </authContext.Provider>
    )
}

export default AuthState;