import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel'

export class IndexCarousel extends Component {
    render() {
        return (
            <div style={{paddingTop:"105px" }} >
  <Carousel fade  data-aos="fade-right"  >
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100 testeandoTam"
                        src="/carrousel/uno.jpg"

                        alt="Uno"
                    />
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100 testeandoTam"
                        src="/carrousel/dos.jpg"

                        alt="Dos"
                    />
                   
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img 
                        className="d-block w-100 testeandoTam"
                        src="/carrousel/tres.jpg"
   
                        alt="Tres"
                    />
                 
                </Carousel.Item>
            </Carousel>
            </div>
          
        )
    }
}



export default IndexCarousel