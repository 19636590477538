/*eslint-disable*/
import React, { useContext, useEffect } from "react";

import { Button, Form, Col } from 'react-bootstrap';
import DeliveryTable from "../../components/Tables/deliveryTable";
import SubDeliveryTable from "../../components/Tables/subDeliveryTable";
import SubDeliveryPrintTable from "../../components/Tables/subDeliveryPrintTable";
import logo from '../../assets/img/Logo.png';
import PedidoState from '../../context/pedidos/pedidoContext';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faTrashAlt, faArrowRight, faCaretRight, faFileDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
//Table
import { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

export default function Pedidos() {
  const [wait, setWait] = React.useState(0);

  let infoPedido = {
    ID: '',
    Cliente: '',
    Total: '',
    Fecha: ''
  }

  const [info, setInfo] = React.useState(infoPedido);

  const columns = [
    {
      dataField: '',
      text: '',
      formatter: seleccionar,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          infoPedido.ID = row.ID;
          var CDate = new Date(row.Fecha);
          infoPedido.Fecha = CDate.getDate() + "-" + (CDate.getUTCMonth() + 1) + "-" + CDate.getFullYear();
          infoPedido.Total = row.Total;
          infoPedido.Cliente = row.Nombre + ' ' + row.Apellido;
          setInfo(infoPedido);
          setShow(true);
          setWait(row.ID);
        }
      },
      headerStyle: () => {
        return { width: "2%" };
      },
      align: 'center',
      csvExport: false
    }, {

      dataField: 'ID',
      text: 'Nro Pedido',
      sort: true,
      filter: textFilter({
        placeholder: 'Nro Pedido...',
        className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
      }),
      align: 'center',
      headerStyle: () => {
        return { width: "5%", textAlign: "center" };
      }
    }, {
      dataField: 'Nombre',
      text: 'Cliente',
      sort: true,
      filter: textFilter({
        placeholder: 'Cliente...',
        className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
      }),
      align: 'center',
      formatter: (cellContent, row) => {
        return (
          <span >{row.Nombre} {row.Apellido}</span>
        );
      },
      headerStyle: () => {
        return { width: "12%", textAlign: "center" };
      },
      editable: false
    }, {
      dataField: 'Comentario',
      text: 'Comentario Pedido',
      sort: true,
      filter: textFilter({
        placeholder: 'Comentario...',
        className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
      }),
      align: 'center',
      headerStyle: () => {
        return { width: "12%", textAlign: "center" };
      },
      editable: false
    }, {
      dataField: 'Email',
      text: 'Correo',
      sort: true,
      align: 'center',
      filter: textFilter({
        placeholder: 'Correo...',
        className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
      }),
      headerStyle: () => {
        return { width: "12%", textAlign: "center" };
      },
      editable: false
    }, {
      dataField: 'Estado',
      text: 'Estado',
      sort: true,
      align: 'center',
      filter: textFilter({
        placeholder: 'Estado...',
        className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
      }),
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
      editable: false
    }, {
      dataField: 'Fecha',
      text: 'Fecha',
      sort: true,
      align: 'center',
      filter: textFilter({
        placeholder: 'Categoria...',
        className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
      }),
      formatter: (cellContent, row) => {

        var CDate = new Date(row.Fecha);
        return (
          <span >{CDate.getDate() + "-" + CDate.getMonth() + "-" + CDate.getFullYear()}</span>
        );
      }
      ,
      headerStyle: () => {
        return { width: "9%", textAlign: "center" };
      },
      editable: false
    }, {
      dataField: 'Total',
      text: 'Total',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <span >${row.Total}</span>
        );
      },
      filter: textFilter({
        placeholder: 'Total...',
        className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
      }),
      headerStyle: () => {
        return { width: "9%", textAlign: "center" };
      },
      editable: false
    }, {
      dataField: '',
      text: '',
      formatter: cambiar,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          var id = row.ID;
          var texto = row.Estado === 'PENDIENTE' ? 'EN PREPARACIÓN' : 'ENTREGADO';
          setTimeout(() => {
            ConfirmChange.open({
              title: 'Cambiar Estado Pedido',
              message: '¿Esta seguro de cambiar al estado "' + texto + '" del Pedido ' + row.ID + '?',
              onok: () => {
                document.body.style.backgroundColor = 'blue';
              },
              registro: id,
              estado: texto,
              estadoAct: row.Estado
            })
          }, 0);
        }
      },
      headerStyle: () => {
        return { width: "2%" };
      },
      align: 'center',
      csvExport: false
    }, {
      dataField: '',
      text: '',
      formatter: eliminar,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          var id = row.ID;

          setTimeout(() => {
            Confirm.open({
              title: 'Eliminar Pedido',
              message: '¿Esta seguro de eliminar el Pedido ' + row.ID + '?',
              onok: () => {
                document.body.style.backgroundColor = 'blue';
              },
              registro: id
            })
          }, 0);
        }
      },
      headerStyle: () => {
        return { width: "2%" };
      },
      align: 'center',
      csvExport: false
    }
  ];

  function eliminar() {
    return (
      <a ><i className="icon" style={{ color: "black", cursor: "pointer" }} ><FontAwesomeIcon icon={faTrashAlt} /></i></a>
    );
  }

  function seleccionar() {
    return (
      <a ><i className="icon" style={{ color: "black", cursor: "pointer" }} ><FontAwesomeIcon icon={faAngleRight} /></i></a>
    );
  }

  function cambiar() {
    return (
      <a ><i className="icon" style={{ color: "black", cursor: "pointer" }} ><FontAwesomeIcon icon={faCaretRight} /></i></a>
    );
  }

  const columnsDet = [, {

    dataField: 'ID',
    text: '',
    sort: true,
    hidden: true,
    align: 'center',
    headerStyle: () => {
      return { width: "5%", textAlign: "center" };
    }
  }, {
      dataField: 'NroProducto',
      text: 'Nro Artículo',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: "12%", textAlign: "center" };
      },
      editable: false
    }, {
      dataField: 'Cantidad',
      text: 'Cantidad',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: "12%", textAlign: "center" };
      }
    }, {
      dataField: 'Precio',
      text: 'Precio Establecido',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      }
    }, {
      dataField: 'SubTotal',
      text: 'SubTotal',
      sort: true,
      align: 'center',
      formatter: (cellContent, row) => {
        return (
          <span >${row.SubTotal}</span>
        );
      }
      ,
      headerStyle: () => {
        return { width: "9%", textAlign: "center" };
      },
      editable: false
    }];

  const columnsPrint = [{
    dataField: 'Cantidad',
    text: 'Cantidad',
    align: 'center',
    headerStyle: () => {
      return { width: "8%", textAlign: "center" };
    }
  }, {
    dataField: 'NroProducto',
    text: 'Detalle',
    align: 'center',
    headerStyle: () => {
      return { width: "70%", textAlign: "center" };
    },
    editable: false
  }, {
    dataField: 'Precio',
    text: 'Unit.',
    align: 'center',
    headerStyle: () => {
      return { width: "5%", textAlign: "center" };
    },
    formatter: (cellContent, row) => {
      return (
        <span >${row.Precio}</span>
      );
    }
  }, {
    dataField: 'SubTotal',
    text: 'Precio',
    align: 'center',
    formatter: (cellContent, row) => {
      return (
        <span >${row.SubTotal}</span>
      );
    }
    ,
    headerStyle: () => {
      return { width: "8%", textAlign: "center" };
    },
    editable: false
  }];



  const Confirm = {
    open(options) {
      options = Object.assign({}, {
        title: '',
        message: '',
        okText: 'Si',
        cancelText: 'Cancelar',
        onok: function () { },
        oncancel: function () { },
        registro: ''
      }, options);

      const html = `
            <div class="confirm">
                <div class="confirm__window">
                    <div class="confirm__titlebar">
                        <span class="confirm__title">${options.title}</span>
                        <button class="confirm__close">&times;</button>
                    </div>
                    <div class="confirm__content">${options.message}</div>
                    <div class="confirm__buttons">
                        <button class="confirm__button confirm__button--ok confirm__button--fill">${options.okText}</button>
                        <button class="confirm__button confirm__button--cancel">${options.cancelText}</button>
                    </div>
                </div>
            </div>
        `;

      const template = document.createElement('template');
      template.innerHTML = html;

      // Elements
      const confirmEl = template.content.querySelector('.confirm');
      const btnClose = template.content.querySelector('.confirm__close');
      const btnOk = template.content.querySelector('.confirm__button--ok');
      const btnCancel = template.content.querySelector('.confirm__button--cancel');

      confirmEl.addEventListener('click', e => {
        if (e.target === confirmEl) {
          options.oncancel();
          this._close(confirmEl);
        }
      });

      btnOk.addEventListener('click', () => {

        var objProducto = new Object();
        objProducto.id = options.registro;
        pedidoDelete({ objProducto });
        window.location.reload(true);

      });

      [btnCancel, btnClose].forEach(el => {
        el.addEventListener('click', () => {
          options.oncancel();
          this._close(confirmEl);
        });
      });

      document.body.appendChild(template.content);
    },

    _close(confirmEl) {
      confirmEl.classList.add('confirm--close');

      confirmEl.addEventListener('animationend', () => {
        document.body.removeChild(confirmEl);
      });
    }
  };


  const ConfirmChange = {
    open(options) {
      options = Object.assign({}, {
        title: '',
        message: '',
        okText: 'Si',
        cancelText: 'Cancelar',
        onok: function () { },
        oncancel: function () { },
        registro: '',
        estado: '',
        estadoAct: ''
      }, options);

      const html = `
            <div class="confirm">
                <div class="confirm__window">
                    <div class="confirm__titlebar">
                        <span class="confirm__title">${options.title}</span>
                        <button class="confirm__close">&times;</button>
                    </div>
                    <div class="confirm__content">${options.message}</div>
                    <div class="confirm__buttons">
                        <button class="confirm__button confirm__button--ok confirm__button--fill">${options.okText}</button>
                        <button class="confirm__button confirm__button--cancel">${options.cancelText}</button>
                    </div>
                </div>
            </div>
        `;

      const template = document.createElement('template');
      template.innerHTML = html;

      // Elements
      const confirmEl = template.content.querySelector('.confirm');
      const btnClose = template.content.querySelector('.confirm__close');
      const btnOk = template.content.querySelector('.confirm__button--ok');
      const btnCancel = template.content.querySelector('.confirm__button--cancel');

      confirmEl.addEventListener('click', e => {
        if (e.target === confirmEl) {
          options.oncancel();
          this._close(confirmEl);
        }
      });

      btnOk.addEventListener('click', () => {
        if (options.estadoAct === "ENTREGADO") {
          window.alert("El Pedido se encuentra en ENTREGADO.");
          this._close(confirmEl);
        } else {
          var objProducto = new Object();
          objProducto.id = options.registro;
          objProducto.estado = options.estado;
          pedidoUpdate({ objProducto });
        }
      });

      [btnCancel, btnClose].forEach(el => {
        el.addEventListener('click', () => {
          options.oncancel();
          this._close(confirmEl);
        });
      });

      document.body.appendChild(template.content);
    },

    _close(confirmEl) {
      confirmEl.classList.add('confirm--close');

      confirmEl.addEventListener('animationend', () => {
        document.body.removeChild(confirmEl);
      });
    }
  };

  const [show, setShow] = React.useState(false);


  const pedidoContext = useContext(PedidoState);
  const { pedido, pedidoDet, obtenerPedidos, obtenerPedidosDet, pedidoDelete, pedidoUpdate, exito } = pedidoContext;
  // Obtener proyectos cuando carga el componente
  useEffect(() => {
    obtenerPedidos();
    if (wait > 0) {
      var objPedido = new Object();
      objPedido.ID = wait;

      obtenerPedidosDet(objPedido);
      setWait(0);
      if (exito) {
        window.location.reload(true);

      }

    }
  }, [wait, exito]);

  function printDiv() {
    var contenido = document.getElementById('areaImprimir').innerHTML;
    var contenidoOriginal = document.body.innerHTML;

    document.body.innerHTML = contenido;

    window.print();

    document.body.innerHTML = contenidoOriginal;
  }
  return (
    <>

      <div data-aos="fade-down">
        <br></br>
        <div className="container col-md-12" style={{ paddingTop: '80px' }} >
          <div className="row col-md-12" style={{ alignContent: "center" }}>
            <a href="/dashboard" style={{ color: "black" }}><h6>Principal</h6></a><h6><FontAwesomeIcon icon={faArrowRight} style={{ width: "80%", paddingLeft: "10px", paddingRight: "2px" }} /></h6><h6 style={{ color: 'red' }}>Pedidos</h6>
          </div>
        </div>
        <div className="container col-md-12" >
          <div className="row col-md-12" >
            <DeliveryTable
              products={pedido}
              columns={columns}
              llave={'ID'}
              indication={'No se encontraron Pedidos.'}
              mensajeMod={'¿Desea guardar los cambios realizados en Productos?'}
            >
            </DeliveryTable>
          </div>
        </div>
        <br />
        {show == true ? <div className="container col-md-12" >
          <div className="row col-md-12"  >
            <SubDeliveryTable
              products={pedidoDet}
              columns={columnsDet}
              llave={'ID'}
              indication={'No se encontraron Productos.'}
              mensajeMod={'¿Desea guardar los cambios realizados en el Pedido?'}
            >
            </SubDeliveryTable>
          </div>
          <div className="row col-md-10" id="areaImprimir" style={{ display: 'none' }} >
            <div className="row col-md-12">
              <div className="col-md-4">
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <img className="Logo" src={logo} alt="Logo" />
                </div>
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <small>San Nicolás - Buenos Aires Tel:336-4534507 / 336-4309308</small>
                </div>
              </div>
              <div className="col-md-8">
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <h3 style={{ marginBottom: '0' }} >PEDIDO</h3>
                </div>
                <div className="col-md-12" style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <small >DOCUMENTO NO VALIDO COMO FACTURA.</small>
                </div>
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <h3 >N°: {info.ID}</h3>
                </div>
              </div>
            </div>

            <div className="row col-md-12">
              <div className="col-md-6">
                <h6>Sres.: {info.Cliente}</h6>
              </div>
              <div className="col-md-4">
                <h6>Fecha: {info.Fecha}</h6>
              </div>
            </div>

            <div className="row col-md-12">
              <div className="col-md-12">
                <h6>Domicilio:</h6>
              </div>
            </div>

            <div className="row col-md-12">
              <div className="col-md-6">
                <h6>Localidad:</h6>
              </div>
              <div className="col-md-4">
                <h6>C.P.:</h6>
              </div>
            </div>

            <div className="row col-md-12">
              <div className="col-md-12">
                <h6>Atención Sr.:</h6>
              </div>
            </div>

            <div className="row col-md-12">
              <SubDeliveryPrintTable
                products={pedidoDet}
                columns={columnsPrint}
                llave={'ID'}

              >
              </SubDeliveryPrintTable>

            </div>
            <div className="row col-md-12" style={{ textAlign: 'end', marginBottom: '10px' }}>
              <div className="col-md-12">
                <h6>TOTAL $ {info.Total}</h6>
              </div>
            </div>

            <div className="row col-md-12" style={{ textAlign: 'end', marginTop: '80px' }}>
              <div className="col-md-3" style={{ textAlign: 'center', borderTop: 'ridge', marginLeft: '20px' }}>
                <h6>RECIBI CONFORME</h6>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3" style={{ textAlign: 'center', borderTop: 'ridge' }}>
                <h6>ACLARACIÓN</h6>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3" style={{ textAlign: 'center', borderTop: 'ridge' }}>
                <h6>FIRMA</h6>
              </div>
            </div>
          </div>

          <div className="row  col-md-12" style={{ justifyContent: "end", marginTop:'20px' }}><Button variant="dark" onClick={printDiv}  ><FontAwesomeIcon icon={faPrint} /> Imprimir </Button></div>


        </div> : null}
      </div>
    </>
  );
}
