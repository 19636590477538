
/*eslint-disable*/
import React, {useState, useContext, useEffect} from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import CartTable from "../Tables/cartTable";

function exportTableToExcel(filename = '') {
  var downloadLink;
  var dataType = 'application/vnd.ms-excel';
  var tableSelect = document.getElementById('test');
  var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

  // Specify file name
  filename = 'Pedido.xls';

  // Create download link element
  downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
    var blob = new Blob(['ufeff', tableHTML], {
      type: dataType
    });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

    // Setting the file name
    downloadLink.download = filename;

    //triggering the function
    downloadLink.click();
  }
}

export function CartModal(props) {


  const [productosLista, setRes] = React.useState(JSON.parse(localStorage.getItem('productos')));
  let act= localStorage.getItem('openPoPup');
  if(act==="true")
  {
    setRes(JSON.parse(localStorage.getItem('productos')));
    localStorage.setItem('openPoPup', false);
  }
  const handleContChange = row => {
    //Añadir producto al carrito


    let productosLS;

    if (localStorage.getItem('productos') === null) {
      productosLS = [];
    }
    else {
      productosLS = JSON.parse(localStorage.getItem('productos'));
    }

    try {

      var select = document.getElementById('inputCant' + row.ID);

      if (select.value > 999) {
        productosLS.forEach(function (productLS) {
          if (productLS.ID === row.ID) {
            productLS.Cantidad = 999;
          }
        });

        document.getElementById('inputCant' + row.ID).value = 999;
        localStorage.setItem('productos', JSON.stringify(productosLS));
      }
      else {
        productosLS.forEach(function (productLS) {
          if (productLS.ID === row.ID) {
            productLS.Cantidad = select.value;
          }
        });

        localStorage.setItem('productos', JSON.stringify(productosLS));
      }
      
    } catch (error) {
      console.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }

  const handleDelChange = row => {
    //Añadir producto al carrito

    let productosLS;

    if (localStorage.getItem('productos') === null) {
      productosLS = [];
    }
    else {
      productosLS = JSON.parse(localStorage.getItem('productos'));
    }

    try {

      productosLS.forEach(function (productLS, index) {
        if (productLS.ID === row.ID) {
          productosLS.splice(index, 1);
        }
      });

      localStorage.setItem('productos', JSON.stringify(productosLS));


      if (localStorage.getItem('productos') === null) {
        setRes([]);
      }
      else {
        setRes(JSON.parse(localStorage.getItem('productos')));
      }
      
    } catch (error) {
      console.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }

  
  const linkAdd = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Form>
        <Form.Group className="mb-3" controlId={'inputCant' + row.ID} style={{ height: "20px" }}>
          <Form.Control type="number" defaultValue={row.Cantidad} onChange={() => {
            handleContChange(row);
          }} />
        </Form.Group>
      </Form>);
  };

  const linkDel = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Form>
        <Button variant="outline-danger" onClick={() => {
          handleDelChange(row);
        }} name={'btn'+row.ID} > X</Button>
      </Form>);
  };
      // Elements

    

  const columns = [{
    dataField: 'ID',
    text: 'id',
    sort: true,
    searchable: false,
    hidden: true
  },
  {
    dataField: 'img',
    isDummyField: true,
    searchable: false,
    formatter: (cellContent, row) => {
      return (
        <div><img src={'/products/' + row.NroProducto + '.jpg'} alt="sans" style={{ width: '30px', height: '30px' }} /></div>
      );
    },
    headerStyle: () => {
      return { width: "1%", textAlign: "center" };
    },
    align: 'center'
  }, {
    dataField: 'NroProducto',
    text: 'Codigo',
    sort: true,
    headerStyle: () => {
      return { width: "5%", textAlign: "center" };
    },
    align: 'center'
  },
  {
    dataField: "Cantidad",
    text: "Cant.",
    formatter: linkAdd,
    align: 'center',
    searchable: false,
    headerStyle: () => {
      return { width: "2%", textAlign: "center" };
    }
  },
  {
    dataField: "Del",
    text: "",
    formatter: linkDel,
    align: 'center',
    searchable: false,
    headerStyle: () => {
      return { width: "2%", textAlign: "center" };
    }
  },
  ];


  const handleClearCartChange = () => {
    localStorage.removeItem('productos');
    location.reload();
  }

  const routeChange = () => {

    let path = `pedido`;
    window.location.href = path;
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      name="test"
      className="confirm--close"
    >
      <Modal.Header closeButton style={{ background: "black", color: "white" }} >
        <h4 className="text-center">Carrito</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-6 col-md-6">
            <Button onClick={routeChange} variant="outline-success">Realizar Pedido</Button>
          </div>
          <div className="col-sm-6 col-md-6" style={{ textAlign: 'end' }} >
            <Button onClick={handleClearCartChange} variant="outline-danger" inline>Cancelar Pedido</Button>
          </div>
        </div>
        <br />
        <CartTable
          products={productosLista}
          columns={columns}
          llave={'ID'}
          indication={'No se encontraron Productos en su Carrito.'}
          name={'test'}
        >
        </CartTable>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="outline-danger" name="btnClose" >Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
}
