import React, { useReducer } from 'react';
import categoriaReducer from './categoriaReducer';
import categoriaContext from './categoriaContext';
import clienteAxios from "../../config/axios";

const OBTENCION_EXITOSA = 'OBTENCION_EXITOSA';
const REGISTRO_ERROR = 'REGISTRO_ERROR';
const OPERACION_EXITOSA = 'OPERACION_EXITOSA';

const CategoriaState = props => {
    const initialState = {
        categorias: [],
        modificaCategoria: null,
        mensaje: '',
        exito: false

    }
    const [state, dispatch] = useReducer(categoriaReducer, initialState);

    const obtenerCategorias = async () => {
        try {
            const resultado = await clienteAxios.post('/category/get');
            if (resultado.status === 200) {
                dispatch({
                    type: OBTENCION_EXITOSA,
                    payload: resultado.data.rows
                })
            }
            else {
      
            }
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const obtenerCategoriasFiltros = async () => {
        try {
            const resultado = await clienteAxios.post('/category/getCategoryFilter');
            if (resultado.status === 200) {
                dispatch({
                    type: OBTENCION_EXITOSA,
                    payload: resultado.data.rows
                })
            }
            else {

            }
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const categoriaUpdate = async registro => {
        try {
            const respuesta = await clienteAxios.post('/category/updateCategory', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const categoriaDelete = async registro => {
        try {
            const respuesta = await clienteAxios.post('/category/deleteCategory', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const categoriaCrear = async registro => {
        try {
            const respuesta = await clienteAxios.post('/category/insertCategory', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            });
        } catch (error) {
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            });
        }
    }

    return (
        <categoriaContext.Provider
            value={{
                modificaCategoria: state.modificaCategoria,
                categorias: state.categorias,
                mensaje: state.mensaje,
                exito: state.exito,
                obtenerCategorias,
                categoriaUpdate,
                categoriaDelete,
                categoriaCrear,
                obtenerCategoriasFiltros
            }}
        >
            {props.children}
        </categoriaContext.Provider>
    )
}


export default CategoriaState;