import React, { useState, useContext, useEffect } from "react";
import { Navbar, Nav, Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faSignInAlt, faShoppingCart, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import AlertaContext from "../../context/alertas/alertaContext";
import AuthContext from "../../context/autenticacion/authContext";

import logo from '../../assets/img/Logo.png';

export default function Reset(props) {
  const [modalShowTree, setModalShowTree] = React.useState(false);

  const history = useHistory();

  const authContext = useContext(AuthContext);
  const {mensaje, autenticadoDash, newPassUsuario } = authContext;

  useEffect(() => {
    if (autenticadoDash) {
      console.log(autenticadoDash);
      alert( "Contraseña actualizada correctamente.");
      history.push('/');
    }
    if(mensaje !==null){
      alert( mensaje.msg);
      console.log(mensaje);
    }
  }, [mensaje,autenticadoDash, props.history]
  )

  const [usuario, guardarUsuario] = useState({
    mailLogin: '',
    passwordLogin: '',
    tokenLogin: ''
  });

  const { mailLogin, passwordLogin, tokenLogin } = usuario;

  const onChange = e => {
    guardarUsuario({
      ...usuario,
      [e.target.name]: e.target.value
    })
  }

  const onSubmit = e => {
    e.preventDefault();

    newPassUsuario({
      mailLogin, passwordLogin, tokenLogin
    });

  }


  return (


    <div className="container">
      <div className="row" style={{ justifyContent: 'center', textAlign: 'center' }} >
        <div className="col-lg-12 login-key">
          <i className="fa fa-key" aria-hidden="true"></i>
        </div>
        <div className="col-lg-12 ">
          <img className="Logo" src={logo} alt="Logo" />

        </div>
        <Form onSubmit={onSubmit}>
          <div className="col-lg-12 login-form">
            <Form.Group controlId="mailLogin" className="form-group">
              <Form.Control type="email" placeholder="Correo" name="mailLogin" value={mailLogin} onChange={onChange} />
            </Form.Group>
            <Form.Group controlId="passwordLogin" lass="form-group">
              <Form.Control type="password" placeholder="Nueva Contraseña" name="passwordLogin" value={passwordLogin} onChange={onChange} />
            </Form.Group>
            <Form.Group controlId="tokenLogin" lass="form-group">
              <Form.Control type="number" placeholder="Token" name="tokenLogin" value={tokenLogin} onChange={onChange} />
            </Form.Group>
            <div className="col-lg-12" style={{ textAlign: 'center' }}>
              <div className="login-btm login-button">
                <Button variant="outline-dark" type="submit"  >
                  Cambiar Contraseña
                </Button>
              </div>
            </div>


          </div>
          <br></br>
        </Form>
      </div>
    </div>



  );
}
