import React from "react";
import { Dropdown, ListGroup } from 'react-bootstrap';
import FileUpload from '../../components/FileUpload/FileUpload';
import Gallery from '../../components/FileUpload/Gallery';
import FileUploadMasive from '../../components/FileUpload/FileUploadMasive';
import clienteAxios from "../../config/axios";
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function Multimedia() {


  const [res, setRes] = React.useState([]);
  const [habilitarMarca, sethabilitarMarca] = React.useState(0);
  const [llamado, setLlamado] = React.useState('');
  const onChange = async e => {
    try {

      if (e === "#marcs") {
        let resultado = await clienteAxios.post('/library/marcs')
        setRes(resultado);
        sethabilitarMarca(1);
        setLlamado('marcs');
      }
      if (e === "#products") {
        let resultado = await clienteAxios.post('/library/products')
        setRes(resultado);
        sethabilitarMarca(1);
        setLlamado('products');
      }
      if (e === "#carrousel") {
        let resultado = await clienteAxios.post('/library/carrousel')
        setRes(resultado);
        sethabilitarMarca(1);
        setLlamado('carrousel');
      }

    } catch (err) {

    }
  };

  return (
    <>
      <div data-aos="fade-down">
        <br></br>
        <div className="container col-md-12" style={{ paddingTop: '70px' }} >
          <div className="row col-md-12" style={{ alignContent: "center" }}>
            <a href="/dashboard" style={{ color: "black" }}><h6>Principal</h6></a><h6><FontAwesomeIcon icon={faArrowRight} style={{ width: "80%", paddingLeft: "10px", paddingRight: "2px" }} /></h6><h6 style={{ color: 'red' }}>Marcas</h6>
          </div>
        </div>
        <div className='container mt-6'>
          <div className='row'>
            <div className='col-5'>
              <h4>Cargar Imagén</h4>
              <hr></hr>
              <FileUpload />
            </div>
            <div className='col-2'></div>
            <div className='col-5'>
              <h4>Carga Masiva de Imagénes</h4>
              <hr></hr>
              <FileUploadMasive />
            </div>
          </div>
        </div>
        <div className='container col-md-12'>
          <div className='col-12'>
            <h4>Galeria de Imagénes</h4>
            <br />
            <Dropdown onSelect={onChange} >
              <Dropdown.Toggle variant="dark" id="dropdown-basic">
                Seleccionar Galeria
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#marcs" >Marcas</Dropdown.Item>
                <Dropdown.Item href="#products" >Productos</Dropdown.Item>
                <Dropdown.Item href="#carrousel" >Carrousel</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <hr></hr>
          </div>
        </div>
        {habilitarMarca > 0 ? <div className='row' style={{placeContent:'center'}} >
          <Gallery data={res.data} llamado={llamado} />
        </div> : null}
      </div>
    </>
  );
}
