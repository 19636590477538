import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import DashboardNavbar from "../components/Navbars/dashboardNavbar";
// views
import Principal from "../views/dashboard/Principal";
import Pedidos from "../views/dashboard/Pedidos";
import Productos from "../views/dashboard/Productos";
import Descuentos from "../views/dashboard/Descuentos";
import Marcas from "../views/dashboard/Marcas";
import Categorias from "../views/dashboard/Categorias";
import SubCategorias from "../views/dashboard/SubCategorias";
import Rubros from "../views/dashboard/Rubros";
import Letras from "../views/dashboard/Letras";
import Multimedia from "../views/dashboard/Multimedia";
import Clientes from "../views/dashboard/Clientes";
import Proveedores from "../views/dashboard/Proveedores";
import Logs from "../views/dashboard/Logs";
import MarcaState from '../context/marcas/marcaState';
import CategoriaState from "../context/categorias/categoriaState";
import SubCategoriaState from "../context/subCategorias/subCategoriaState";
import ProductoState from "../context/productos/productoState";
import LetraState from "../context/letras/letraState";
import DescuentoState from "../context/descuentos/descuentoState";
import PedidoState from "../context/pedidos/pedidoState";
import Catalogo from "../views/dashboard/Catalogo";

export default function Auth() {
  return (
    <>
      <MarcaState>
        <CategoriaState>
          <SubCategoriaState>
            <ProductoState>
              <LetraState>
                <DescuentoState>
                  <PedidoState>
                <DashboardNavbar ></DashboardNavbar>
                <main>
                  <Switch>
                    <Route path="/dashboard/Principal" exact component={Principal} />
                    <Route path="/dashboard/Catalogo" exact component={Catalogo} />
                    <Route path="/dashboard/Pedidos" exact component={Pedidos} />
                    <Route path="/dashboard/Productos" exact component={Productos} />
                    <Route path="/dashboard/Descuentos" exact component={Descuentos} />
                    <Route path="/dashboard/Marcas" exact component={Marcas} />
                    <Route path="/dashboard/Categorias" exact component={Categorias} />
                    <Route path="/dashboard/SubCategorias" exact component={SubCategorias} />
                    <Route path="/dashboard/Rubros" exact component={Rubros} />
                    <Route path="/dashboard/Letras" exact component={Letras} />
                    <Route path="/dashboard/Multimedia" exact component={Multimedia} />
                    <Route path="/dashboard/Clientes" exact component={Clientes} />
                    <Route path="/dashboard/Proveedores" exact component={Proveedores} />
                    <Route path="/dashboard/Logs" exact component={Logs} />
                    <Redirect from="/dashboard" to="/dashboard/principal" />
                  </Switch>
                </main>
                </PedidoState>
                </DescuentoState>
              </LetraState>
            </ProductoState>
          </SubCategoriaState>
        </CategoriaState>
      </MarcaState>
    </>
  );
}
