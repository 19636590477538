/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import DescuentoContext from "../../context/descuentos/descuentoContext";
import AlertaContext from "../../context/alertas/alertaContext";

export function DiscountModal(props) {

  const history = useHistory();
  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const descuentoContext = useContext(DescuentoContext);
  const {exito, mensaje, descuentoCrear } = descuentoContext;


  useEffect(() => {
    if(exito){
      location.reload();
    }
    if (mensaje) {
      mostrarAlerta(mensaje.msg, "cat");
    }
  }, [mensaje,exito, props.history]
  )

  const [descuento, guardarDescuento] = useState({
    nombre: '',
    monto: '',
    porcentaje:'',
    letra: ''
  });

  const { nombre, letra, monto, porcentaje } = descuento;

  const onChange = e => {
    guardarDescuento({
      ...descuento,
      [e.target.name]: e.target.value
    })
  }

  const onSubmit = e => {
    e.preventDefault();
    let id =document.getElementById('letraSub');
    console.log(id);
    let letra = id.innerText;
    descuentoCrear({nombre, letra, monto, porcentaje});
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="confirm__titlebar ">
        <h5 className="confirm__title">Nuevo Descuento</h5>
        <h6 id="letraSub" style={{color:"#222222"}}>{props.letraid}</h6>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:"#eeeeee"}}>
        <Form onSubmit={onSubmit}>
          
            <Form.Group as={Col} md={6} controlId="nombre">
              <Form.Label >Producto: </Form.Label>
              <Form.Control type="text" placeholder="Ingrese Producto..." name="nombre" value={nombre} onChange={onChange} />
            </Form.Group>
        
            <Form.Group as={Col} controlId="monto">
              <Form.Label >Monto:</Form.Label>
              <Form.Control type="text" placeholder="Ingrese Monto..." name="monto" value={monto} onChange={onChange} />
            </Form.Group>
        
            <Form.Group as={Col} controlId="porcentaje">
              <Form.Label >Porcentaje:</Form.Label>
              <Form.Control type="text" placeholder="Ingrese Porcentaje..." name="porcentaje" value={porcentaje} onChange={onChange} />
            </Form.Group>
          <div className="row" style={{ justifyContent: "center" }}>
            {alerta ? (<div className={`alerta ${alerta.categoria}`} style={{ backgroundColor: "red", color: "white", textAlign: "center", borderRadius: "5px", padding: "0% 2% 0% 2%" }}>{alerta.msg}</div>) : null}
          </div>
          <br></br>
          <Row className="mb-3" style={{ justifyContent: "center", textAlign: "center" }} >
            <Button className="confirm__button confirm__button--ok confirm__button--fill" variant="success" type="submit" style={{backgroundColor:"green",borderColor:"green"}} >
              Guardar
            </Button>
          </Row>
        </Form>
       
      </Modal.Body>
    </Modal>
  );
}
