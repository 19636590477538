import React, { useReducer} from 'react';
import pedidoReducer from './pedidoReducer';
import pedidoContext from './pedidoContext';
import clienteAxios from "../../config/axios";
import tokenAuth from "../../config/token";

const OBTENCION_EXITOSA = 'OBTENCION_EXITOSA';
const OBTENCION_EXITOSA_DET = 'OBTENCION_EXITOSA_DET';
const REGISTRO_ERROR = 'REGISTRO_ERROR';
const OPERACION_EXITOSA = 'OPERACION_EXITOSA';
const PEDIDO_EXITOSO = 'PEDIDO_EXITOSO';


const PedidoState = props =>{
    const initialState = {
        pedido : [],
        pedidoDet : [],
        lista : [],
        modificaProducto: null,
        mensaje: '',
        exito: false
    }
    const [state, dispatch] = useReducer(pedidoReducer,initialState);

    const obtenerPedidos = async () => {
        try {
            const resultado = await clienteAxios.post('/delivery/getAll');

            dispatch({
                type: OBTENCION_EXITOSA,
                payload: resultado.data.rows
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const obtenerPedidosDet = async ID => {
        try {
            const resultado = await clienteAxios.post('/delivery/getDet',ID);

            dispatch({
                type: OBTENCION_EXITOSA_DET,
                payload: resultado.data.rows
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

   const pedidoUpdate = async registro => {
        try {
            const respuesta = await clienteAxios.post('/delivery/updateDelivery', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const pedidoDetUpdate = async registro => {
        try {
            const respuesta = await clienteAxios.post('/delivery/updateDeliveryDet', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const pedidoDelete = async registro => {
        try {
            const respuesta = await clienteAxios.post('/delivery/deleteDelivery', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }

    const pedidoCrear = async registro => {
        try {

            const respuesta = await clienteAxios.post('/products/insertProducts', registro);
            dispatch({
                type: OPERACION_EXITOSA,
                payload: respuesta.data
            });
        } catch (error) {
            const alerta = {
                msg: error.response.data,
                categoria: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            });
        }
    }


    const realizarPedidoDash = async pedido => {
        try {
         
            const resultado = await clienteAxios.post('/delivery/createDash',pedido);

            dispatch({
                type: PEDIDO_EXITOSO,
                payload: resultado.data.rows
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            console.log(error);
            
            dispatch({
                type: REGISTRO_ERROR,
                payload: alerta
            })
        }
    }


    return(
        <pedidoContext.Provider
        value={{
            modificaProducto: state.modificaProducto,
            pedido: state.pedido,
            pedidoDet: state.pedidoDet,
            mensaje: state.mensaje,
            exito: state.exito,
            obtenerPedidos,
            pedidoUpdate,
            pedidoDelete,
            pedidoCrear,
            obtenerPedidosDet,
            pedidoDetUpdate,
            realizarPedidoDash,
            lista: state.lista
        }}
        >
            {props.children}
        </pedidoContext.Provider>
    )
}


export default PedidoState;