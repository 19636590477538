/*eslint-disable*/
import React from "react";
import { Navbar, Nav, Form, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { MyVerticallyCenteredModalTwo } from '../Modal/indexModal';
import '../../assets/css/indexFooter.css';

export default function FooterNavbar() {
    const [modalShowTwo, setModalShowTwo] = React.useState(false);
    return (
        <>
            <div className="footer-dark" data-aos="fade-up">
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-md-3 item">
                                <h3 className="text-center">Contactanos</h3>
                                <ul>
                                   
                                    <li><a href="https://api.whatsapp.com/send?phone=543364309308&text=Hola!%20Quisiera%20Asesoramiento.">+54 336 430-9308</a></li>
                                    <li><a href="https://api.whatsapp.com/send?phone=543364534507&text=Hola!%20Quisiera%20Asesoramiento.">+54 336 453-4507</a></li>
                                 
                                    <li><a href="mailto:nelsoningoglia@gmail.com">nelsoningoglia@gmail.com</a></li>
                                </ul>
                            </div>
                            <div className="col-sm-3 col-md-3 item">
                             
                            </div>
                            <div className="col-sm-6 col-md-3 item">
                            
                            </div>
                            <div className="col-md-3 item text-justify item">
                            <ul>
                                    <li><a><h6  onClick={() => setModalShowTwo(true)}>Nosotros</h6></a></li>
                                   
                                    <MyVerticallyCenteredModalTwo
                                        show={modalShowTwo}
                                        onHide={() => setModalShowTwo(false)} />
                            
                                </ul>
                      
                            </div>
                            
                            <div className="col item social">
                                <a href="https://www.facebook.com/accesoriosdelautomotor/"><i className="icon ion-social-facebook"><FontAwesomeIcon icon={faFacebookF} /></i></a>
                                <a href="https://www.instagram.com/juan.i.mayorista/?hl=es-la"><i className="icon ion-social-instagram"><FontAwesomeIcon icon={faInstagram} /></i></a>
                                <a href="mailto:nelsoningoglia@gmail.com"><i className="icon ion-social-twitter"><FontAwesomeIcon icon={faAt} /></i></a>
                                <a href="https://api.whatsapp.com/send?phone=543364534507&text=Hola!%20Quisiera%20Asesoramiento."><i className="icon ion-social-snapchat"><FontAwesomeIcon icon={faWhatsapp} /></i></a>
                            </div>
                        </div>
                    
                        <p className="copyright">Todos los Derechos Reservados Juan.I © 2021. Creado Por
                        </p>
                        <ul>
                            <li className="text-center"><a href="http://dament.com.ar/" > Dament</a></li></ul>
                    </div>
                </footer>
            </div>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/js/bootstrap.bundle.min.js"></script>
        </>
    );
}
