import React, { Component,useState } from 'react'
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import '../../assets/css/indexDropdown.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export class IndexInput extends Component {


  render() {

  
  const routeChange = () =>{ 
    
    var select = document.getElementById('buscador');
    let path = `catalog?search=`+select.value ; 
   window.location.href = path;
  }
    return (

        <InputGroup className="mb-3">
        <FormControl
          placeholder="Buscar Producto"
          aria-label="Buscar Producto"
          aria-describedby="basic-addon2"
          id="buscador"
        />
        <InputGroup.Append>
          <Button variant="outline-secondary" onClick={routeChange}><FontAwesomeIcon icon={faSearch}  /> Buscador</Button>
        </InputGroup.Append>
      </InputGroup>
    )
  }
}



export default IndexInput



