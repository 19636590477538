import React from 'react';

export default function Register() {

  return (
    <>
        <h1>Desde Nuevo usuario</h1>
    </>
  );
}
