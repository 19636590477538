/*eslint-disable*/
import React, { useContext, useEffect } from "react";
import IndexNavbar from "../components/Navbars/indexNavbar";
import IndexCarousel from "../components/Carousel/indexCarousel";
import MarcsCarousel from "../components/Carousel/marcsCarousel";
import IndexFooter from "../components/Footer/indexFooter";
import CategoryDropdown from "../components/Dropdown/categoryDropdown";
import RubroDropdown from "../components/Dropdown/rubroDropdown";
import IndexInput from "../components/Input/indexInput";
import { Col } from 'react-bootstrap';
import marcaContext from '../context/marcas/marcaContext';

export default function Index() {

  const marcasContext = useContext(marcaContext);

  const { marcas, obtenerMarcasFront } = marcasContext;

  var showing = 0;
  // Obtener proyectos cuando carga el componente
  useEffect(() => {
    
    obtenerMarcasFront(window.location.protocol);


    // eslint-disable-next-line
  }, []);

  if (marcas.length > 0) {
    showing = 1;
  }

  const ImgData = marcas.map((element) => [
    { alt: element.alt, src: element.src, classId: element.classId, duracion: "1000" }
  ]);

  return (
    <>
      <style type="text/css">
        {`
        .flat {
          background-color: #282D32;
          color: white;
          padding:15px;
        }

        `}
      </style>
      <IndexNavbar fade="fade-down" ></IndexNavbar>
      <IndexCarousel></IndexCarousel>

      <div className="flat" data-aos="fade-down">
        <h3 className="text-center" >¿Que necesitas?</h3>
        <div className="container">
          <Col xs={12} sm={6} md={6} style={{ marginLeft: "auto", marginRight: "auto" }}>
            <IndexInput></IndexInput>
          </Col>
        </div>
      </div>
      <br></br>
     
      <br></br>
      <br></br>
      <div className="container" >
        <div className="row col-md-12" style={{ alignContent: "center", justifyContent: "center" }} data-aos="fade-down">
          <a href="/catalog?news=novedades" style={{ fontSize: '2rem', color: 'black' }}><span >Conoce las&nbsp;</span><span style={{ color: 'red' }}>Novedades&nbsp;</span><span >del Mes!</span></a>
        </div>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <div className="flat">
        <h3 className="text-center" data-aos="fade-right">Nuestras Marcas</h3>
      </div>
      <br></br>
      <div >
        {showing === 1 ? <MarcsCarousel marca={marcas}></MarcsCarousel> : null}
      </div>
      <br></br>

      <IndexFooter></IndexFooter>
    </>
  );
}
