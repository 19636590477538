import React, { useContext, useEffect } from "react";
//Components
import { Button, Form, Col } from 'react-bootstrap';
import { DiscountModal } from '../../components/Modal/discountModal';
import DiscountTable from "../../components/Tables/discountTable";
import letraContext from '../../context/letras/letraContext';
import descuentoContext from "../../context/descuentos/descuentoContext";
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlusSquare, faArrowRight } from '@fortawesome/free-solid-svg-icons';
//Table
import { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ProcessFileUpload from "../../components/FileUpload/ProcessFileUpload";


export default function Letras() {

  const [show, setShow] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [modalShowTwo, setModalShowTwo] = React.useState(false);

  const letrasContext = useContext(letraContext);
  const { letras, obtenerLetras } = letrasContext;

  const descuentosContext = useContext(descuentoContext);
  const { descuento, obtenerDescuentos, descuentoDelete } = descuentosContext;


  // Obtener proyectos cuando carga el componente
  useEffect(() => {
    obtenerLetras();
    var select = document.getElementById('letra');
    if (descuento.length > 0 || select.options[select.selectedIndex].value > 0) {
      setShow(true);
    }
    else {
      setShow(false);
    }
  }, [descuento]);

  const handleCategoriaChange = () => {
    var select = document.getElementById('letra');
    var idLetra = select.options[select.selectedIndex].value;
    var objLetra = new Object;
    objLetra.id = idLetra;
    setValue(idLetra);
    obtenerDescuentos(objLetra);

  }

  const columns = [{
    dataField: 'ID',
    text: 'id',
    sort: true,
    hidden: true,
    csvExport: false
  }, {
    dataField: 'NroProducto',
    text: 'NroProducto',
    sort: true,
    filter: textFilter({
      placeholder: 'Ingrese Producto...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "25%", textAlign: "center" };
    }
  }, {
    dataField: 'Monto',
    text: 'Monto',
    sort: true,
    filter: textFilter({
      placeholder: 'Ingrese Monto...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "15%", textAlign: "center" };
    }
  }, {
    dataField: 'Porcentaje',
    text: 'Porcentaje',
    sort: true,
    filter: textFilter({
      placeholder: 'Ingrese Porcentaje...',
      className: 'btn-danger', style: { backgroundColor: 'white', borderColor: 'grey', color: 'black' }
    }),
    headerStyle: () => {
      return { width: "15%", textAlign: "center" };
    }
  }, {
    dataField: '',
    text: '',
    formatter: eliminar,
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        var id = row.ID;

        setTimeout(() => {
          Confirm.open({
            title: 'Eliminar Descuento',
            message: '¿Esta seguro de eliminar el Desuento del Producto ' + row.NroProducto + '?',
            onok: () => {
              document.body.style.backgroundColor = 'blue';
            },
            registro: id
          })
        }, 0);
      }
    },
    headerStyle: () => {
      return { width: "3%" };
    },
    align: 'center',
    csvExport: false
  }
  ];

  function eliminar() {
    return (
      <a><i className="icon" style={{ color: "black", cursor: "pointer" }} ><FontAwesomeIcon icon={faTrashAlt} /></i></a>
    );
  }

  const Confirm = {
    open(options) {
      options = Object.assign({}, {
        title: '',
        message: '',
        okText: 'Si',
        cancelText: 'Cancelar',
        onok: function () { },
        oncancel: function () { },
        registro: ''
      }, options);

      const html = `
            <div class="confirm">
                <div class="confirm__window">
                    <div class="confirm__titlebar">
                        <span class="confirm__title">${options.title}</span>
                        <button class="confirm__close">&times;</button>
                    </div>
                    <div class="confirm__content">${options.message}</div>
                    <div class="confirm__buttons">
                        <button class="confirm__button confirm__button--ok confirm__button--fill">${options.okText}</button>
                        <button class="confirm__button confirm__button--cancel">${options.cancelText}</button>
                    </div>
                </div>
            </div>
        `;

      const template = document.createElement('template');
      template.innerHTML = html;

      // Elements
      const confirmEl = template.content.querySelector('.confirm');
      const btnClose = template.content.querySelector('.confirm__close');
      const btnOk = template.content.querySelector('.confirm__button--ok');
      const btnCancel = template.content.querySelector('.confirm__button--cancel');

      confirmEl.addEventListener('click', e => {
        if (e.target === confirmEl) {
          options.oncancel();
          this._close(confirmEl);
        }
      });

      btnOk.addEventListener('click', () => {

        var objDescuento = new Object();
        objDescuento.id = options.registro;
        descuentoDelete({ objDescuento });
        window.location.reload(true);

      });

      [btnCancel, btnClose].forEach(el => {
        el.addEventListener('click', () => {
          options.oncancel();
          this._close(confirmEl);
        });
      });

      document.body.appendChild(template.content);
    },

    _close(confirmEl) {
      confirmEl.classList.add('confirm--close');

      confirmEl.addEventListener('animationend', () => {
        document.body.removeChild(confirmEl);
      });
    }
  };


  return (
    <>
      <div data-aos="fade-down">
        <br></br>
        <div className="container col-md-12" style={{ paddingTop: '70px' }} >
          <div className="row col-md-12" style={{ alignContent: "center" }}>
            <a href="/dashboard" style={{ color: "black" }}><h6>Principal</h6></a><h6><FontAwesomeIcon icon={faArrowRight} style={{ width: "80%", paddingLeft: "10px", paddingRight: "2px" }} /></h6><h6 style={{ color: 'red' }}>Descuentos</h6>
          </div>
        </div>
        <Form.Group as={Col} md={2}>
          <Form.Label >Letra:</Form.Label>
          <select className="form-control" onChange={handleCategoriaChange} id="letra">
            <option value={0}>Seleccione Letra</option>
            {
              letras.map((item, i) => {
                return (
                  <option key={i} value={item.ID}>{item.Nombre}</option>
                )
              }, this)
            }
          </select>
        </Form.Group>
        <div className="container col-md-12" style={{ paddingBottom: '5px' }} >
          <div className="row col-md-12">
            <div className="col-md-4">
              {show ? <ProcessFileUpload /> : null}
            </div>
            <div className="col-md-8" style={{ textAlign: "end",alignSelf:"end" }}>

              {show ? <Button variant="dark" onClick={() => setModalShowTwo(true)}><FontAwesomeIcon icon={faPlusSquare} id="agregarDescuento" /> Nuevo Descuento</Button> : null}

              <DiscountModal
                show={modalShowTwo}
                onHide={() => setModalShowTwo(false)}
                letraid={value} />
            </div>
          </div>

        </div>
        <div className="container col-md-12" >
          <div className="row col-md-12" >
            <DiscountTable
              products={descuento}
              columns={columns}
              llave={'ID'}
              indication={'No se encontraron descuentos.'}
              mensajeMod={'¿Desea guardar los cambios realizados en Descuentos?'}
            >
            </DiscountTable>
          </div>
        </div>
      </div>
    </>
  );
}
