import React, { Fragment, useState } from 'react';
import Message from './Message';
import Progress from './Progress';
import clienteAxios from "../../config/axios";

const FileUpload = () => {
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('Buscar Imagén');
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const onChange = e => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const onSubmit = async e => {

    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    var select = document.getElementById('carpeta');
    var value = select.options[select.selectedIndex].value;
    if(value !== 'seleccione'){
      try {
        const res = await clienteAxios.post('/upload/'+value, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            setUploadPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          }
        });
  
        // Clear percentage
        setTimeout(() => setUploadPercentage(0), 10000);
  
        const { fileName, filePath } = res.data;
  
        setUploadedFile({ fileName, filePath });
  
        setMessage('Archivo Subido Correctamente');
      } catch (err) {
        if (err.response.status === 500) {
          setMessage('Hubo un problema con el Servidor');
        } else {
          setMessage(err.response.data.msg);
        }
        setUploadPercentage(0)
      }
    }
    else{
      setMessage('Debe seleccionar destino.');
    }
  };

  return (
    <Fragment>
      {message ? <Message msg={message} /> : null}
      <form onSubmit={onSubmit}>
        <div className='custom-file'>
        <label style={{width:'20%'}}>Destino:</label>
          <select name="carpeta" id="carpeta" style={{width:'80%'}}>
            <option value="seleccione">Seleccione</option>
            <option value="marcs">Marcas</option>
            <option value="products">Productos</option>
            <option value="carrousel">Carrousel</option>
          </select>
        </div>
        <div className='custom-file mb-4'>
          <input
            type='file'
            className='custom-file-input'
            id='customFile'
            onChange={onChange}
          />
          <label className='custom-file-label' htmlFor='customFile'>
            {filename}
          </label>
        </div>

        <Progress percentage={uploadPercentage} visible={false} />

        <input
          type='submit'
          value='Subir Imagén'
          className='btn btn-danger btn-block mt-4'
        />
      </form>
      {uploadedFile ? (
        <div className='row mt-5'>
          <div className='col-md-6 m-auto'>
            <h3 className='text-center'>{uploadedFile.fileName}</h3>
            <img style={{ width: '100%' }} src={uploadedFile.filePath} alt='' />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default FileUpload;
