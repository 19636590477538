/*eslint-disable*/
import React, { useContext, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Button } from 'react-bootstrap';
// es6
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import marcaContext from '../../context/marcas/marcaContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

const defaultSorted = [{
  dataField: 'id',
  order: 'asc'
}];

export default (props) => {

  const marcasContext = useContext(marcaContext);
  const { marcaUpdate,exito } = marcasContext;
  // Obtener proyectos cuando carga el componente

  useEffect(() => {
    if(exito){
      window.location.reload(true);

    }
  }, [exito]
  )
  function beforeSaveCell(oldValue, newValue, row, column, done) {

    setTimeout(() => {
      Confirm.open({
        title: 'Modificar Marca',
        message: '¿Esta seguro de modificar la Marca ' + row.Nombre + '?',
        onok: () => {
          document.body.style.backgroundColor = 'blue';
        },
        valor: newValue,
        id: row.ID,
        columna: column.dataField
      })
    }, 0);
    return { async: true };
  }

  const Confirm = {
    open(options) {
      options = Object.assign({}, {
        title: '',
        message: '',
        okText: 'Si',
        cancelText: 'Cancelar',
        onok: function () { },
        oncancel: function () { },
        valor: '',
        id: '',
        columna: ''
      }, options);

      const html = `
            <div class="confirm">
                <div class="confirm__window">
                    <div class="confirm__titlebar">
                        <span class="confirm__title">${options.title}</span>
                        <button class="confirm__close">&times;</button>
                    </div>
                    <div class="confirm__content">${options.message}</div>
                    <div class="confirm__buttons">
                        <button class="confirm__button confirm__button--ok confirm__button--fill" style="background: #009879;border: 2px solid #009879" >${options.okText}</button>
                        <button class="confirm__button confirm__button--cancel" style="border: 2px solid #009879;color: #009879;">${options.cancelText}</button>
                    </div>
                </div>
            </div>
        `;

      const template = document.createElement('template');
      template.innerHTML = html;

      // Elements
      const confirmEl = template.content.querySelector('.confirm');
      const btnClose = template.content.querySelector('.confirm__close');
      const btnOk = template.content.querySelector('.confirm__button--ok');
      const btnCancel = template.content.querySelector('.confirm__button--cancel');

      confirmEl.addEventListener('click', e => {
        if (e.target === confirmEl) {
          options.oncancel();
          this._close(confirmEl);
        }
      });

      btnOk.addEventListener('click', () => {
        var objMarca = new Object;
        objMarca.valor = options.valor;
        objMarca.id = options.id;
        objMarca.columna = options.columna;
        marcaUpdate({ objMarca });
       
      });

      [btnCancel, btnClose].forEach(el => {
        el.addEventListener('click', () => {
          options.oncancel();
          this._close(confirmEl);
        });
      });

      document.body.appendChild(template.content);
    },

    _close(confirmEl) {
      confirmEl.classList.add('confirm--close');

      confirmEl.addEventListener('animationend', () => {
        document.body.removeChild(confirmEl);
      });
    }
  };

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button variant="dark" onClick={handleClick} ><FontAwesomeIcon icon={faFileDownload} /> Exportar Archivo</Button>
        <a ><i className="icon" style={{ fontSize: "30px" }}></i></a>
      </div>
    );
  };

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = 'black';
      activeStyle.color = 'white';
      activeStyle.border = 'none';
    } else {
      activeStyle.backgroundColor = '#6c757d';
      activeStyle.color = 'white';
      activeStyle.border = 'none';
    }
    if (typeof page === 'string') {
      activeStyle.backgroundColor = '#6c757d';
      activeStyle.color = 'white';
      activeStyle.border = 'none';
    }
    return (
      <li className="page-item">
        <Button onClick={handleClick} variant="dark" style={activeStyle}>{page}</Button>
      </li>
    );
  };

  const options = {
    pageButtonRenderer
  };

  return (
    <ToolkitProvider
      keyField={props.llave}
      data={props.products}
      columns={props.columns}
      noDataIndication={props.indication}
      exportCSV={{ onlyExportFiltered: true, exportAll: false, fileName: 'Marcas.csv' }}
    >
      {
        props => (
          <div>
            <BootstrapTable
              {...props.baseProps}
              noDataIndication={'No se encontraron marcas.'}
              striped
              hover
              condensed
              tabIndexCell
              defaultSorted={defaultSorted}
              bootstrap4
              pagination={paginationFactory(options)}
              filter={filterFactory()}
              cellEdit={cellEditFactory({ mode: 'dbclick', beforeSaveCell })}
            />
            <div style={{ textAlign: "end" }}>
              <MyExportCSV className="btn" {...props.csvProps}></MyExportCSV>
            </div>
          </div>
        )
      }
    </ToolkitProvider>
  );
}
