/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import SubCategoriaContext from "../../context/subCategorias/subCategoriaContext";
import CategoriaContext from "../../context/categorias/categoriaContext";
import AlertaContext from "../../context/alertas/alertaContext";

export function SubCategoryModal(props) {

  const history = useHistory();
  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const subCategoriaContext = useContext(SubCategoriaContext);
  const { exito, mensaje, subCategoriaCrear } = subCategoriaContext;

  const categoriaContext = useContext(CategoriaContext);
  const { categorias, modificaCategorias, obtenerCategorias } = categoriaContext;

  useEffect(() => {
    obtenerCategorias();
    if (exito) {
      location.reload();
    }
    if (mensaje) {
      mostrarAlerta(mensaje.msg, "cat");
    }
  }, [mensaje, exito, modificaCategorias, props.history]
  )

  const [subCategoria, guardarSubCategoria] = useState({
    nombre: '',
    descripcion: ''
  });

  const { nombre, descripcion } = subCategoria;

  const onChange = e => {
    guardarSubCategoria({
      ...subCategoria,
      [e.target.name]: e.target.value
    })
  }

  const onSubmit = e => {
    e.preventDefault();
    var select = document.getElementById('categoria');
    var idCategoria = select.options[select.selectedIndex].value;
    if (idCategoria != '0') {
      subCategoriaCrear({
        nombre, idCategoria, descripcion
      });

    }
    else {
      mostrarAlerta('Debe Seleccionar Categoría', "cat");
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="confirm__titlebar ">
        <h5 className="confirm__title">Nueva SubCategoría</h5>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#eeeeee" }}>
        <Form onSubmit={onSubmit}>

          <Form.Group as={Col} md={6} controlId="nombre">
            <Form.Label >Nombre: </Form.Label>
            <Form.Control type="text" placeholder="Ingrese SubCategoría..." name="nombre" value={nombre} onChange={onChange} />
          </Form.Group>

          <Form.Group as={Col} md={6}>

            <Form.Label >Categoría:</Form.Label>
            <select className="form-control" id="categoria">
              <option value={0}>Seleccione Categoría</option>
              {
                categorias.map((item, i) => {
                  return (
                    <option key={i} value={item.ID}>{item.Nombre}</option>
                  )
                }, this)
              }
            </select>
          </Form.Group>

          <Form.Group as={Col} controlId="descripcion">
            <Form.Label >Descripción:</Form.Label>
            <Form.Control type="text" placeholder="Ingrese Descripción..." name="descripcion" value={descripcion} onChange={onChange} />
          </Form.Group>

          <div className="row" style={{ justifyContent: "center" }}>
            {alerta ? (<div className={`alerta ${alerta.categoria}`} style={{ backgroundColor: "red", color: "white", textAlign: "center", borderRadius: "5px", padding: "0% 2% 0% 2%" }}>{alerta.msg}</div>) : null}
          </div>
          <br></br>
          <Row className="mb-3" style={{ justifyContent: "center", textAlign: "center" }} >
            <Button className="confirm__button confirm__button--ok confirm__button--fill" variant="success" type="submit" style={{backgroundColor:"green",borderColor:"green"}} >
              Guardar
            </Button>
          </Row>
        </Form>

      </Modal.Body>
    </Modal>
  );
}
