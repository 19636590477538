const OBTENCION_EXITOSA = 'OBTENCION_EXITOSA';
const REGISTRO_ERROR = 'REGISTRO_ERROR';
const OPERACION_EXITOSA = 'OPERACION_EXITOSA';

export default (state, action) => {
    switch (action.type) {
        case OBTENCION_EXITOSA:
            return {
                ...state,
                letras: action.payload
            }
        case OPERACION_EXITOSA:
            return {
                ...state,
                letras: action.payload,
                exito: true
            }
        case REGISTRO_ERROR:
            return {
                ...state,
                mensaje: action.payload
            }
        default:
            return state;
    }
}



