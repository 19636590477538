/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";
import IndexNavbar from "../../components/Navbars/indexNavbar";
import CatalogTable from "../../components/Tables/catalogTable";
import Select from 'react-select';
import { Button, Badge } from 'react-bootstrap';
//Table
import { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import MarcaContext from "../../context/marcas/marcaContext";
import CategoriaContext from "../../context/categorias/categoriaContext";
import ProductoState from '../../context/productos/productoContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';

export default function Principal(props) {

  let ocultar = true;

  if (localStorage.getItem('token') === null) {
    ocultar = true;
  }
  else {
    ocultar = false;
  }

  const handleAddChange = row => {
    //Añadir producto al carrito

    const infoProducto = {
      ID: row.ID,
      NroProducto: row.NroProducto,
      Precio: row.Precio,
      PrecioPromocional: row.PrecioPromocional,
      Cantidad: 1,
      Descripcion: row.Descripcion,
      PrecioExclusivo: row.PrecioExclusivo,
      CantidadMinima: row.CantidadMinima
    }

    let productosLS;

    if (localStorage.getItem('productos') === null) {
      productosLS = [];
    }
    else {
      productosLS = JSON.parse(localStorage.getItem('productos'));
    }
    let contadorVeces = 0;
    productosLS.forEach(function (productLS) {
      if (productLS.ID === infoProducto.ID) {
        productLS.Cantidad++;
        contadorVeces++;
      }
    });

    if (contadorVeces > 0) {

      localStorage.setItem('productos', JSON.stringify(productosLS));

    }
    else {

      if (localStorage.getItem('productos') === null) {
        productosLS = [];
      }
      else {
        productosLS = JSON.parse(localStorage.getItem('productos'));
      }

      productosLS.push(infoProducto);
      //Agregamos al LS
      localStorage.setItem('productos', JSON.stringify(productosLS));
      document.getElementById("counter").innerHTML = productosLS.length;

    }

  }

  const linkAdd = (cell, row, rowIndex, formatExtraData) => {
    if (row.Stock > 0) {
      return (
        <Button variant="outline-dark"
          style={{ padding: '4px' }}
          onClick={() => {
            handleAddChange(row);
          }}
        >
          <FontAwesomeIcon icon={faCartPlus} />
        </Button>
      );
    }
    else {
      return (
        <Button variant="outline-dark"
          style={{ padding: '4px' }}
          onClick={() => {
            handleAddChange(row);
          }}
          disabled
        >
          <FontAwesomeIcon icon={faCartPlus} />
        </Button>
      );
    }
  };

  const Confirm = {
    open(options) {
      options = Object.assign({}, {
        title: '',
        message: '',
        desc: '',
        okText: 'Si',
        cancelText: 'Cerrar',
        onok: function () { },
        oncancel: function () { },
        registro: ''
      }, options);

      const html = `
            <div class="confirm">
                <div class="confirm__window">
                    <div class="confirm__titlebar">
                        <span class="confirm__title">${options.title}</span>
                        <button class="confirm__close">&times;</button>
                    </div>
                    <div style="text-Align:center" ><img src="/products/${options.message}.jpg" alt="sans" style="width:200px;height:200px" /></div>
                    <div class="confirm__content">${options.desc}</div>
                    <div class="confirm__buttons">
                        <button class="confirm__button confirm__button--cancel">${options.cancelText}</button>
                    </div>
                </div>
            </div>
        `;

      const template = document.createElement('template');
      template.innerHTML = html;

      // Elements
      const confirmEl = template.content.querySelector('.confirm');
      const btnClose = template.content.querySelector('.confirm__close');
      const btnOk = template.content.querySelector('.confirm__button--ok');
      const btnCancel = template.content.querySelector('.confirm__button--cancel');


      [btnCancel, btnClose].forEach(el => {
        el.addEventListener('click', () => {
          options.oncancel();
          this._close(confirmEl);
        });
      });

      document.body.appendChild(template.content);
    },

    _close(confirmEl) {
      confirmEl.classList.add('confirm--close');

      confirmEl.addEventListener('animationend', () => {
        document.body.removeChild(confirmEl);
      });
    }
  };

  const columns = [{
    dataField: 'ID',
    text: 'id',
    sort: true,
    searchable: false,
    hidden: true
  },
  {
    dataField: 'img',
    isDummyField: true,
    searchable: false,
    formatter: (cellContent, row) => {
      return (
        <div><img src={'/products/' + row.NroProducto+ '.jpg'} alt="sans" style={{ width: '30px', height: '30px' }} /></div>
      );
    },
    headerStyle: () => {
      return { width: "5%", textAlign: "center" };
    },
    align: 'center',
    events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        var id = row.ID;

        setTimeout(() => {
          Confirm.open({
            title: 'Producto: ' + row.NroProducto,
            message: row.NroProducto,
            desc: row.Descripcion,
            onok: () => {
              document.body.style.backgroundColor = 'blue';
            },
            registro: id
          })
        }, 0);
      }
    }
  }, {
    dataField: 'NroProducto',
    text: 'Código',
    sort: true,
    headerStyle: () => {
      return { width: "8%", textAlign: "center" };
    },
    align: 'center'
  }, {
    dataField: 'Descripcion',
    text: 'Descripción',
    sort: true,
    headerStyle: () => {
      return { width: "20%", textAlign: "center", };
    }
  }, {
    dataField: 'Marca',
    text: 'Marca',
    sort: true,
    headerStyle: () => {
      return { width: "7%", textAlign: "center" };
    },
    editable: false
  }, {
    dataField: 'Categoria',
    text: 'Categoria',
    sort: true,
    headerStyle: () => {
      return { width: "7%", textAlign: "center" };
    },
    editable: false
  }, {
    dataField: 'SubCategoria',
    text: 'SubCategoria',
    sort: true,
    headerStyle: () => {
      return { width: "7%", textAlign: "center" };
    },
    editable: false
  }, {
    dataField: 'PrecioPromocional',
    text: 'Precio',
    align: 'center',
    hidden: ocultar,
    searchable: false,
    formatter: (cellContent, row) => {
      const precioExclusivo = row.PrecioExclusivo;
      const precioPromocional = row.PrecioPromocional;
      const cantidadMinima = row.CantidadMinima;
      if (precioExclusivo > 0 && precioPromocional > 0) {
        if(precioExclusivo > precioPromocional){
          if (cantidadMinima > 0) {
            return (
              <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#4F7CFF" }} ><span className="">${row.Precio.toFixed(2)} </span> ${row.PrecioPromocional.toFixed(2)} <small>Min {row.CantidadMinima} uni.</small> </span>
            );
          } else {
            return (
              <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#4F7CFF" }} > <span className="strikethrough">${row.Precio.toFixed(2)} </span> ${row.PrecioPromocional.toFixed(2)}  </span>
            );
          }
        }else{
          return (
            <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#DA5E30" }} > <span className="strikethrough">${row.Precio.toFixed(2)} </span> ${row.PrecioExclusivo.toFixed(2)}  </span>
          );
        }
      } else {
        if (precioExclusivo > 0) {
          return (
            <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#DA5E30" }} > <span className="strikethrough">${row.Precio.toFixed(2)} </span> ${row.PrecioExclusivo.toFixed(2)}  </span>
          );
        }
        else {
          if (precioPromocional > 0) {
            if (cantidadMinima > 0) {
              return (
                <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#4F7CFF" }} ><span className="" style={{color: "#000" }}>${row.Precio.toFixed(2)} <small> uni.</small> /</span> ${row.PrecioPromocional.toFixed(2)} <small>Min {row.CantidadMinima} uni.</small> </span>
              );
            } else {
              return (
                <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#4F7CFF" }} > <span className="strikethrough">${row.Precio.toFixed(2)} </span> ${row.PrecioPromocional.toFixed(2)}  </span>
              );
            }
          }
          else
            return (
              <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600' }} > {row.Precio === 0 ? "" : "$" + row.Precio.toFixed(2)}</span>
            );
        };
      }
    },
    headerStyle: () => {
      return { width: "7%", textAlign: "center" };
    }
  },
  {
    dataField: 'Stock',
    isDummyField: true,
    text: 'Stock',
    searchable: false,
    formatter: (cellContent, row) => {
      if (row.Stock > 0) {
        return (
          <div><Badge bg="success" style={{ backgroundColor: "#2EE200", height: "15px", width: "15px", borderRadius: "50%", display: "inline-block" }} ></Badge></div>
        );
      }
      else {
        return (
          <div><Badge bg="danger" style={{ backgroundColor: "red", height: "15px", width: "15px", borderRadius: "50%", display: "inline-block" }} ></Badge></div>
        );
      }
    },
    headerStyle: () => {
      return { width: "3%", textAlign: "center" };
    },
    align: 'center',
  },
  {
    dataField: "follow",
    text: "",
    formatter: linkAdd,
    align: 'center',
    hidden: ocultar,
    searchable: false,
    headerStyle: () => {
      return { width: "3%", textAlign: "center" };
    }
  },
  ];

  var url = new URL(window.location);
  var params = new URLSearchParams(url.search);

  const marcaContext = useContext(MarcaContext);
  const { marcas, obtenerMarcasFiltro } = marcaContext;

  const categoriaContext = useContext(CategoriaContext);
  const { categorias, obtenerCategoriasFiltros } = categoriaContext;

  const productoContext = useContext(ProductoState);
  const { productos, obtenerProductosFront, obtenerProductosFrontClient } = productoContext;

  // Obtener proyectos cuando carga el componente
  useEffect(() => {

    if (ocultar) {
      obtenerProductosFront();
    } else {
      obtenerProductosFrontClient();
    }

  }, []
  )

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white', borderColor: 'black' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#000',
        cursor: isDisabled ? 'not-allowed' : 'default'
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: 'white'
      };
    },
  };

  return (
    <>
      <IndexNavbar fade="fade-down"></IndexNavbar>
      <div className="container col-md-12" style={{ height: "100%", paddingTop: "150px" }} data-aos="fade-down">
        <div className="row col-md-12" style={{ textAlign: "center" }} >
        </div>
        <br></br>
        <div className="row col-md-12" >
          <CatalogTable
            products={productos}
            columns={columns}
            llave={'ID'}
            indication={'No se encontraron Productos.'}
            url={params.get('search')}
          >
          </CatalogTable>
        </div>
      </div>
    </>
  );
}
