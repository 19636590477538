/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";
import IndexNavbar from "../../components/Navbars/indexNavbar";
import { Button, Modal, Form, Badge } from 'react-bootstrap';
import CartTable from "../../components/Tables/cartTable";
import Select from 'react-select';
//Table
import { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import MarcaContext from "../../context/marcas/marcaContext";
import CategoriaContext from "../../context/categorias/categoriaContext";
import ProductoState from '../../context/productos/productoContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';

export default function Principal(props) {

  const [total, setTotal] = useState(0);

  const handleContChange = row => {
    //Añadir producto al carrito


    let productosLS;

    if (localStorage.getItem('productos') === null) {
      productosLS = [];
    }
    else {
      productosLS = JSON.parse(localStorage.getItem('productos'));
    }

    try {

      var select = document.getElementById('inputCant' + row.ID);

      if (select.value > 999) {
        productosLS.forEach(function (productLS) {
          if (productLS.ID === row.ID) {
            productLS.Cantidad = 999;
          }
        });

        document.getElementById('inputCant' + row.ID).value = 999;
        localStorage.setItem('productos', JSON.stringify(productosLS));
      }
      else {
        if (select.value === "" || select.value < 1) {
          productosLS.forEach(function (productLS) {
            if (productLS.ID === row.ID) {
              productLS.Cantidad = 1;
            }
          });

          document.getElementById('inputCant' + row.ID).value = 1;
          localStorage.setItem('productos', JSON.stringify(productosLS));
        } else {
          productosLS.forEach(function (productLS) {
            if (productLS.ID === row.ID) {
              productLS.Cantidad = select.value;
            }
          });

          localStorage.setItem('productos', JSON.stringify(productosLS));
        }

      }
      let totalDentro = 0;
      productosLS.forEach(function (productLS) {
        const precioExclusivo = productLS.PrecioExclusivo;
        const precioPromocional = productLS.PrecioPromocional;
        const precio = productLS.Precio;
        const cantidadMinima = productLS.CantidadMinima;
        const cantidad = productLS.Cantidad;
        if (precioExclusivo > 0 && precioPromocional > 0) {
          if (precioExclusivo > precioPromocional) {
            if (cantidadMinima > 0) {
              if (cantidad >= cantidadMinima) {
                totalDentro = totalDentro + (cantidad * precioPromocional);
              } else {
                totalDentro = totalDentro + (cantidad * precio);
              }
            } else {
              totalDentro = totalDentro + (cantidad * precioPromocional);
            }
          } else {
            totalDentro = totalDentro + (cantidad * precioExclusivo);
          }
        } else {
          if (precioExclusivo > 0) {
            totalDentro = totalDentro + (cantidad * precioExclusivo);
          }
          else {
            if (precioPromocional > 0) {
              if (cantidadMinima > 0) {
                if (cantidad >= cantidadMinima) {
                  totalDentro = totalDentro + (cantidad * precioPromocional);
                } else {
                  totalDentro = totalDentro + (cantidad * precio);
                }
              } else {
                totalDentro = totalDentro + (cantidad * precioPromocional);
              }
            }
            else {
              totalDentro = totalDentro + (cantidad * precio);
            }
          };
        }
      });
      setTotal(totalDentro);

    } catch (error) {
      console.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }

  const handleDelChange = row => {
    //Añadir producto al carrito

    let productosLS;

    if (localStorage.getItem('productos') === null) {
      productosLS = [];
    }
    else {
      productosLS = JSON.parse(localStorage.getItem('productos'));
    }

    try {

      productosLS.forEach(function (productLS, index) {
        if (productLS.ID === row.ID) {
          productosLS.splice(index, 1);
        }
      });

      localStorage.setItem('productos', JSON.stringify(productosLS));

      window.location.reload(true);
    } catch (error) {
      console.error(error);
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }

  const linkAdd = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Form>
        <Form.Group className="mb-3" controlId={'inputCant' + row.ID} style={{ height: "20px" }} >
          <Form.Control type="number" defaultValue={row.Cantidad} onChange={() => {
            handleContChange(row);
          }} />
        </Form.Group>
      </Form>);
  };

  const linkDel = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Form>
        <Button variant="outline-danger" onClick={() => {
          handleDelChange(row);
          props.onHide;
        }} name={'btn' + row.ID} > X</Button>
      </Form>);
  };


  const columns = [{
    dataField: 'ID',
    text: 'id',
    sort: true,
    searchable: false,
    hidden: true
  },
  {
    dataField: 'img',
    isDummyField: true,
    searchable: false,
    formatter: (cellContent, row) => {
      return (
        <div><img src={'/products/' + row.NroProducto + '.jpg'} alt="sans" style={{ width: '30px', height: '30px' }} /></div>
      );
    },
    headerStyle: () => {
      return { width: "1%", textAlign: "center" };
    },
    align: 'center'
  }, {
    dataField: 'NroProducto',
    text: 'Codigo',
    sort: true,
    headerStyle: () => {
      return { width: "5%", textAlign: "center" };
    },
    align: 'center'
  }, {
    dataField: 'Descripcion',
    text: 'Descripcion',
    sort: true,
    headerStyle: () => {
      return { width: "10%", textAlign: "center" };
    },
    align: 'center'
  }, {
    dataField: 'PrecioPromocional',
    text: 'Precio',
    align: 'center',
    searchable: false,
    formatter: (cellContent, row) => {
      const precioExclusivo = row.PrecioExclusivo;
      const precioPromocional = row.PrecioPromocional;
      const cantidadMinima = row.CantidadMinima;
      if (precioExclusivo > 0 && precioPromocional > 0) {
        if (precioExclusivo > precioPromocional) {
          if (cantidadMinima > 0) {
            return (
              <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#4F7CFF" }} ><span className="">${row.Precio} </span> ${row.PrecioPromocional.toFixed(2)} <small>Min {row.CantidadMinima} uni.</small> </span>
            );
          } else {
            return (
              <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#4F7CFF" }} > <span className="strikethrough">${row.Precio.toFixed(2)} </span> ${row.PrecioPromocional.toFixed(2)}  </span>
            );
          }
        } else {
          return (
            <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#DA5E30" }} > <span className="strikethrough">${row.Precio.toFixed(2)} </span> ${row.PrecioExclusivo.toFixed(2)}  </span>
          );
        }
      } else {
        if (precioExclusivo > 0) {
          return (
            <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#DA5E30" }} > <span className="strikethrough">${row.Precio.toFixed(2)} </span> ${row.PrecioExclusivo.toFixed(2)}  </span>
          );
        }
        else {
          if (precioPromocional > 0) {
            if (cantidadMinima > 0) {
              return (
                <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#4F7CFF" }} ><span className="" style={{ color: "#000" }}>${row.Precio.toFixed(2)} <small> uni.</small> /</span> ${row.PrecioPromocional.toFixed(2)} <small>Min {row.CantidadMinima} uni.</small> </span>
              );
            } else {
              return (
                <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600', color: "#4F7CFF" }} > <span className="strikethrough">${row.Precio.toFixed(2)} </span> ${row.PrecioPromocional.toFixed(2)}  </span>
              );
            }
          }
          else
            return (
              <span className="" style={{ fontFamily: 'sans-serif', fontWeight: '600' }} > {row.Precio === 0 ? "" : "$" + row.Precio.toFixed(2)}</span>
            );
        };
      }
    },
    headerStyle: () => {
      return { width: "8%", textAlign: "center" };
    }
  },
  {
    dataField: "Cantidad",
    text: "Cantidad",
    formatter: linkAdd,
    align: 'center',
    searchable: false,
    headerStyle: () => {
      return { width: "2%", textAlign: "center" };
    }
  },
  {
    dataField: "Del",
    text: "",
    formatter: linkDel,
    align: 'center',
    searchable: false,
    headerStyle: () => {
      return { width: "2%", textAlign: "center" };
    }
  },
  ];

  let productosLS;
  let totalFinal = 0;

  if (localStorage.getItem('productos') === null) {
    productosLS = [];
  }
  else {

    productosLS = JSON.parse(localStorage.getItem('productos'));
    productosLS.forEach(function (productLS) {
      const precioExclusivo = productLS.PrecioExclusivo;
      const precioPromocional = productLS.PrecioPromocional;
      const precio = productLS.Precio;
      const cantidadMinima = productLS.CantidadMinima;
      const cantidad = productLS.Cantidad;
      if (precioExclusivo > 0 && precioPromocional > 0) {
        if (precioExclusivo > precioPromocional) {
          if (cantidadMinima > 0) {
            if (cantidad >= cantidadMinima) {
              totalFinal = totalFinal + (cantidad * precioPromocional);
            } else {
              totalFinal = totalFinal + (cantidad * precio);
            }
          } else {
            totalFinal = totalFinal + (cantidad * precioPromocional);
          }
        } else {
          totalFinal = totalFinal + (cantidad * precioExclusivo);
        }
      } else {
        if (precioExclusivo > 0) {
          totalFinal = totalFinal + (cantidad * precioExclusivo);
        }
        else {
          if (precioPromocional > 0) {
            if (cantidadMinima > 0) {
              if (cantidad >= cantidadMinima) {
                totalFinal = totalFinal + (cantidad * precioPromocional);
              } else {
                totalFinal = totalFinal + (cantidad * precio);
              }
            } else {
              totalFinal = totalFinal + (cantidad * precioPromocional);
            }
          }
          else {
            totalFinal = totalFinal + (cantidad * precio);
          }
        };
      }
    });

  }
  const productoContext = useContext(ProductoState);
  const { exito, realizarPedido } = productoContext;

  // Obtener proyectos cuando carga el componente
  useEffect(() => {
    if (exito == true) {
      window.alert("Se ha generado correctamente el pedido. Pronto recibira las notificaciones");
      localStorage.removeItem('productos');
      location.reload();
    }
  }, [productosLS, exito]
  )

  const handleClearCartChange = () => {
    localStorage.removeItem('productos');
    location.reload();
  }

  


  const onSend = () => {
    var comentario = document.getElementById("Comentario");
    productosLS = JSON.parse(localStorage.getItem('productos'));
    console.log(productosLS);
    const formData = new FormData();
    productosLS.forEach(function (productLS) {

    });
    formData.append('productos', JSON.stringify(productosLS));
    formData.append('total', totalFinal !== 0 ? totalFinal : total);
    formData.append('comentario', comentario.value);
    realizarPedido(formData);
  }


  return (
    <>
      <IndexNavbar ></IndexNavbar>
      <div className="container col-md-12" style={{ height: "100%", paddingTop: "110px" }}>
        <h3 style={{ textAlign: "center" }} >Su Pedido</h3>
        <div className="row col-md-12" style={{ textAlign: "center" }} >
        </div>
        <br></br>
        <div className="row col-md-12" >
          <CartTable
            products={productosLS}
            columns={columns}
            llave={'ID'}
            indication={'No se encontraron Productos en su Carrito.'}
            name={'test'}
          >
          </CartTable>
        </div>
        <h4 style={{ textAlign: "end", marginRight: "40px" }} >*Total ${total !== 0 ? total : totalFinal}</h4>
        <br />

        <div className="row">
          <div className="col-sm-6 col-md-6">
            <Form.Group className="mb-3" controlId="Comentario"   >
              <Form.Label>Comentario</Form.Label>
              <Form.Control as="textarea" rows={3}  />
            </Form.Group>
          </div>
          <div className="col-sm-6 col-md-6" style={{ textAlign: 'end' }} >
            {total !== 0 ? <Button onClick={onSend} variant="outline-success" style={{ marginRight: '20px' }} >Confirmar Pedido</Button> : totalFinal !== 0 ? <Button onClick={onSend} variant="outline-success" style={{ marginRight: '20px' }} >Confirmar Pedido</Button> : <Button onClick={onSend} variant="outline-success" style={{ marginRight: '20px' }} disabled >Confirmar Pedido</Button>}
            {total !== 0 ? <Button onClick={handleClearCartChange} variant="outline-danger" inline style={{ marginRight: '40px' }} >Cancelar Pedido</Button> : totalFinal !== 0 ? <Button onClick={handleClearCartChange} variant="outline-danger" inline style={{ marginRight: '40px' }} >Cancelar Pedido</Button> : <Button onClick={handleClearCartChange} variant="outline-danger" inline style={{ marginRight: '40px' }} disabled>Cancelar Pedido</Button>}

          </div>
        </div>
        <br />
        <h6 style={{ textAlign: "end", marginRight: "40px" }} >* El Total puede presentar variaciones que le seran comunicadas.</h6>
      </div>

    </>
  );
}
