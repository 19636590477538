const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
const REGISTRO_EXITOSO_DASH = 'REGISTRO_EXITOSO_DASH';
const REGISTRO_ERROR = 'REGISTRO_ERROR';
const OBTENER_USUARIO = 'OBTENER_USUARIO';
const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
const TOKEN_EXITOSO = 'TOKEN_EXITOSO';
const LOGIN_ERROR = 'LOGIN_ERROR';
const CERRAR_SESION = 'CERRAR_SESION';
const OBTENCION_EXITOSA = 'OBTENCION_EXITOSA';
const OPERACION_EXITOSA = 'OPERACION_EXITOSA';

export default (state, action) => {
    switch (action.type) {
        case REGISTRO_EXITOSO:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                autenticado: true,
                mensaje: null
            }
        case TOKEN_EXITOSO:
            return {
                ...state,
                autenticado: true,
                mensaje: null
            }
        case REGISTRO_ERROR:
            return {
                ...state,
                token: null,
                usuario: null,
                autenticado: null,
                mensaje: action.payload
            }
        case OBTENER_USUARIO:
            return {
                auth: null
            }
        case LOGIN_EXITOSO:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                autenticado: true,
                mensaje: null
            }
        case LOGIN_ERROR:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                mensaje: action.payload
            }
        case CERRAR_SESION:
            return {
                auth: null
            }
        case OBTENCION_EXITOSA:
            return {
                ...state,
                clientes: action.payload
            }
        case OPERACION_EXITOSA:
            return {
                ...state,
                marcas: action.payload,
                exito: true
            }
        case REGISTRO_EXITOSO_DASH:
            localStorage.setItem('tokenDash', action.payload.token);
            return {
                ...state,
                autenticadoDash: true,
                mensaje: null
            }
        default:
            return state;
    }
}



