
/*eslint-disable*/
import React from "react";
import { Button, Modal } from 'react-bootstrap';


export function MyVerticallyCenteredModalTwo(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
  
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center">Nosotros</h4>
          <p>
          <p className="text-justify">Distribuidora Juan.I se inició en 2016. Es una empresa familiar fundada por Nelson Ingoglia, dedicada a la venta de insumos, accesorios, cosmética del rubro automotor.</p>
                          <p className="text-justify">Nuestro objetivo es a través de calidad, precio e innovación, brindar a nuestros clientes el mejor de los servicios. </p>
                          <p className="text-justify">Buscamos cumplir ampliamente todas sus expectativas, construyendo relaciones mutuamente beneficiosas y perdurable, con total compromiso y responsabilidad.</p>
                          <p className="text-center"> Agradecemos su visita a nuestra página deseando continuar este contacto.</p>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} variant="outline-dark">Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  