import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

//layouts
import Auth from "./layouts/Auth.js"
import Dashboard from "./layouts/Dashboard.js"

//views
import Index from "./views/Index.js";
import Reset from './views/auth/Reset.js';
import Catalog from "./views/catalog/Catalog.js";
import Pedido from "./views/catalog/Pedido.js";
import AlertaState from './context/alertas/alertaState.js';
import AuthState from './context/autenticacion/authState.js';
import MarcaState from './context/marcas/marcaState.js';
import ProductoState from './context/productos/productoState.js';
import CategoriaState from './context/categorias/categoriaState.js';
import SubCategoriaState from './context/subCategorias/subCategoriaState.js';

const PrivateRoute = ({ component: Component, handleChildFunc, ...rest }) => {
  const token = localStorage.getItem('tokenDash');
            
  return <Route {...rest} render={(props) => (
    token !== null
          ? <Component {...props} user={token} handleChildFunc={handleChildFunc}/>
          : <Redirect to='/auth' />
      )} 
  />
}

class App extends Component {

  componentDidMount() {
    AOS.init({
      duration: 2000
    })
  }

  render() {
    return (
      <MarcaState>
        <ProductoState>
          <CategoriaState>
            <SubCategoriaState>
              <AlertaState>
                <AuthState>
                  <Router >
                    <Switch>
                      {/* add routes with layouts */}
                      <Route path="/auth" component={Auth} />
                      <PrivateRoute path="/dashboard" component={Dashboard} />
                      <Route path="/ResetPassword" component={Reset} />
                      <Route path="/Catalog" component={Catalog} />
                      <Route path="/Pedido" component={Pedido} />                      
                      {/* add routes without layouts */}
                      <Route path="/" exact component={Index} />
                      {/* add redirect for first page */}
                    </Switch>
                  </Router>
                </AuthState>
              </AlertaState>
            </SubCategoriaState>
          </CategoriaState>
        </ProductoState>
      </MarcaState>
    )
  }
};


export default App;
