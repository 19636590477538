/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import ProductoContext from "../../context/productos/productoContext";
import SubCategoriaContext from "../../context/subCategorias/subCategoriaContext";
import CategoriaContext from "../../context/categorias/categoriaContext";
import MarcaContext from "../../context/marcas/marcaContext";
import AlertaContext from "../../context/alertas/alertaContext";


export function ProductModal(props) {

  const history = useHistory();
  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const productoContext = useContext(ProductoContext);
  const { mensaje, exito, productoCrear } = productoContext;

  const subCategoriaContext = useContext(SubCategoriaContext);
  const { subCategorias, obtenerSubCategoriasSeleccionada } = subCategoriaContext;

  const categoriaContext = useContext(CategoriaContext);
  const { categorias, obtenerCategorias } = categoriaContext;

  const marcaContext = useContext(MarcaContext);
  const { marcas, obtenerMarcas } = marcaContext;

  useEffect(() => {
    obtenerCategorias();
    obtenerMarcas();
    if (exito) {
      location.reload();
    }
    if (mensaje) {
      mostrarAlerta(mensaje.msg, "cat");
    }

  }, [mensaje, exito, props.history]
  )

  const [producto, guardarProducto] = useState({
    nroArticulo: '',
    nombre: '',
    descripcion: '',
    costo: '0',
    precio: '0',
    precioP: '0',
    comision: '0',
    stock: '0'
  });

  const { nombre, nroArticulo, descripcion, costo, precio, precioP, comision, stock } = producto;

  const handleCategoriaChange = () => {
    var select = document.getElementById('categoria');
    var idCategoria = select.options[select.selectedIndex].value;
    var objSubCategoria = new Object;
    objSubCategoria.id = idCategoria;
    obtenerSubCategoriasSeleccionada(objSubCategoria);
  }

  const onChange = e => {
    guardarProducto({
      ...producto,
      [e.target.name]: e.target.value
    })
  }


  const onSubmit = e => {
    e.preventDefault();
    var select = document.getElementById('categoria');
    var idCategoria = select.options[select.selectedIndex].value;
    select = document.getElementById('subCategoria');
    var idSubCategoria = select.options[select.selectedIndex].value;
    select = document.getElementById('marca');
    var marca = select.options[select.selectedIndex].value;
    var mensaje = '';


    if (idSubCategoria == '0') {
      mostrarAlerta('Debe Seleccionar SubCategoría', "sca");
      mensaje = '2';
    }
    if (idCategoria == '0') {
      mostrarAlerta('Debe Seleccionar Categoría', "cat");
      mensaje = '1';
    }
    if (marca == '0') {
      mostrarAlerta('Debe Seleccionar Marca', "mar");
      mensaje = '3';
    }
    if (mensaje == '') {
      productoCrear({
        nombre, nroArticulo, descripcion, marca, idCategoria, idSubCategoria, costo, precio, precioP, comision, stock
      });
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="confirm__titlebar ">
        <h5 className="confirm__title">Nuevo Producto</h5>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#eeeeee" }}>
        <Form onSubmit={onSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md={6} controlId="nroArticulo">
              <Form.Label >Nro Artículo: </Form.Label>
              <Form.Control type="text" placeholder="Ingrese Artículo..." name="nroArticulo" value={nroArticulo} onChange={onChange} />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="nombre">
              <Form.Label >Nombre: </Form.Label>
              <Form.Control type="text" placeholder="Ingrese Nombre..." name="nombre" value={nombre} onChange={onChange} />
            </Form.Group>
          </Row>

          <Form.Group as={Col} controlId="descripcion">
            <Form.Label >Descripción:</Form.Label>
            <Form.Control type="text" placeholder="Ingrese Descripción..." name="descripcion" value={descripcion} onChange={onChange} />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} md={6}>
              <Form.Label ></Form.Label>
              <select className="form-control" onChange={handleCategoriaChange} id="marca">
                <option value={0}> Marca</option>
                {
                  marcas.map((item, i) => {
                    return (
                      <option key={i} value={item.ID}>{item.Nombre}</option>
                    )
                  }, this)
                }
              </select>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label ></Form.Label>
              <select className="form-control" onChange={handleCategoriaChange} id="categoria">
                <option value={0}> Categoría</option>
                {
                  categorias.map((item, i) => {
                    return (
                      <option key={i} value={item.ID}>{item.Nombre}</option>
                    )
                  }, this)
                }
              </select>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={6}>
              <Form.Label ></Form.Label>
              <select className="form-control" id="subCategoria">
                <option value={0}> SubCategoría</option>
                {
                  subCategorias.map((item, i) => {
                    return (
                      <option key={i} value={item.ID}>{item.Nombre}</option>
                    )
                  }, this)
                }
              </select>
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="costo">
              <Form.Label >Costo: </Form.Label>
              <Form.Control type="number" placeholder="Ingrese Costo..." name="costo" value={costo} onChange={onChange} />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={6} controlId="precio">
              <Form.Label >Precio: </Form.Label>
              <Form.Control type="number" placeholder="Ingrese Precio..." name="precio" value={precio} onChange={onChange} />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="precioP">
              <Form.Label >Precio Promocional: </Form.Label>
              <Form.Control type="number" placeholder="Ingrese Precio Promocional..." name="precioP" value={precioP} onChange={onChange} />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md={6} controlId="comision">
              <Form.Label >Comisión Vendedor: </Form.Label>
              <Form.Control type="number" placeholder="Ingrese Comisión..." name="comision" value={comision} onChange={onChange} />
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="stock">
              <Form.Label >Stock: </Form.Label>
              <Form.Control type="number" placeholder="Ingrese stock..." name="stock" value={stock} onChange={onChange} />
            </Form.Group>
          </Row>

          <div className="row" style={{ justifyContent: "center" }}>
            {alerta ? (<div className={`alerta ${alerta.categoria}`} style={{ backgroundColor: "red", color: "white", textAlign: "center", borderRadius: "5px", padding: "0% 2% 0% 2%" }}>{alerta.msg}</div>) : null}
          </div>
          <br></br>
          <Row className="mb-3" style={{ justifyContent: "center", textAlign: "center" }} >
            <Button className="confirm__button confirm__button--ok confirm__button--fill" variant="success" type="submit" style={{ backgroundColor: "green", borderColor: "green" }} >
              Guardar
            </Button>
          </Row>
        </Form>

      </Modal.Body>
    </Modal>
  );
}
