/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";
import { Navbar, Nav, Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faSignInAlt, faShoppingCart, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import AlertaContext from "../../context/alertas/alertaContext";
import AuthContext from "../../context/autenticacion/authContext";
import { CartModal } from '../Modal/cartModal';
// logo
import logo from '../../assets/img/Logo.png';
import '../../assets/css/indexNavbar.css';



export default function IndexNavbar(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [CartmodalShow, setCartModalShow] = React.useState(false);
  const [modalShowTwo, setModalShowTwo] = React.useState(false);
  
  const authContext = useContext(AuthContext);
  const { autenticado, valToken } = authContext;

  let productosLS;
  let singUp;
  let pass=false;
  if (localStorage.getItem('productos') === null) {
    productosLS = [];
  }
  else {
    productosLS = JSON.parse(localStorage.getItem('productos'));
  }

  if (localStorage.getItem('token') === null) {
    singUp = 0;
  }
  else {
    if(autenticado==null){
      valToken();
      singUp = 1;
    }
  }

  let contador = productosLS.length;
  const onSubmit = e => {
    localStorage.removeItem('token');

    let path = `/`;
    window.location.href = path;
  }

  var url = new URL(window.location);
  const handleAddChange = row => {
 
  localStorage.setItem('openPoPup', true);
  
  setCartModalShow(true)
  }
  return (
    <>
      <Navbar bg="light" expand="lg" className=" fixed-top" data-aos={props.fade}>
        <Navbar.Brand href="/"><img className="Logo" src={logo} alt="Logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/catalog"><span className="colorMenu">Catálogo</span></Nav.Link>
            <Nav.Link href="/catalog"><span className="colorMenu">Novedades</span></Nav.Link>
            <Nav.Link href="/catalog"><span className="colorMenu">Ofertas</span></Nav.Link>
            <Nav.Link href="/catalog"><span className="colorMenu">Ingresos</span></Nav.Link>
          </Nav>
          <Nav.Item >
            <Form inline>
            
              {singUp === 0 ?
              null :
                <Button variant="outline-dark" style={{ marginRight: "10px", marginBottom: "5px" }} onClick={() => handleAddChange()} >
                  <FontAwesomeIcon icon={faShoppingCart} /> <span style={{ color: "green", font: "small-caption", fontFamily: 'sans-serif' }} id="counter">{contador}</span>
                </Button>
              }
              <CartModal
                show={CartmodalShow}
                onHide={() => setCartModalShow(false)} />
              {url.pathname === "/" ? <Button variant="outline-dark" onClick={() => setModalShowTwo(true)} style={{ marginRight: "10px", marginBottom: "5px", width: "140px" }} >
                <FontAwesomeIcon icon={faBriefcase} /><span className="button-text"> La Empresa</span>
              </Button>
                : null}
              <MyVerticallyCenteredModalTwo
                show={modalShowTwo}
                onHide={() => setModalShowTwo(false)} />
            </Form>
          </Nav.Item>
          {singUp === 0 ? <Form inline>
            <Button variant="outline-dark" onClick={() => setModalShow(true)} style={{ marginBottom: "5px", width: "140px" }}>
              <FontAwesomeIcon icon={faSignInAlt} /><span className="button-text"> Iniciar Sesión</span>
            </Button>
            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)} />
          </Form> :
            <Form inline>
              <Button variant="outline-dark" onClick={onSubmit} style={{ marginBottom: "5px", width: "140px" }}>
                <FontAwesomeIcon icon={faSignOutAlt} /><span className="button-text"> Cerrar Sesión</span>
              </Button>
              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)} />
            </Form>}


        </Navbar.Collapse>
      </Navbar>
    </>
  );
}


function MyVerticallyCenteredModal(props) {
  const [modalShowTree, setModalShowTree] = React.useState(false);

  const history = useHistory();

  const authContext = useContext(AuthContext);
  const { autenticado, authCliente } = authContext;

  useEffect(() => {
    if (autenticado) {
      history.push('/catalog');
      location.reload();
    }
  }, [autenticado, props.history]
  )

  const [usuario, guardarUsuario] = useState({
    mailLogin: '',
    passwordLogin: ''
  });

  const { mailLogin, passwordLogin } = usuario;

  const onChange = e => {
    guardarUsuario({
      ...usuario,
      [e.target.name]: e.target.value
    })
  }

  const onSubmit = e => {
    e.preventDefault();

    authCliente({
      mailLogin, passwordLogin
    });

  }


  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Body>
        <br></br>
        <h4 className="text-center">Iniciar Sesión</h4>
        <br></br>
        <Form className="text-center" onSubmit={onSubmit}>
          <Form.Group controlId="mailLogin">
            <Form.Control type="email" placeholder="Email" name="mailLogin" value={mailLogin} onChange={onChange} />
          </Form.Group>
          <Form.Group controlId="passwordLogin">
            <Form.Control type="password" placeholder="Contraseña" name="passwordLogin" value={passwordLogin} onChange={onChange} />
          </Form.Group>
          <br></br>
          <Button variant="outline-dark" type="submit"  >
            Iniciar Sesión
          </Button>
          <br></br>
          <a href="/resetpassword">Olvide la Contraseña</a>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cerrar</Button>
        <Button variant="outline-dark" onClick={() => setModalShowTree(true)} style={{ marginBottom: "5px", width: "140px" }}>
          <FontAwesomeIcon icon={faSignInAlt} /><span className="button-text">  Registrarse</span>
        </Button>
        <MyVerticallyCenteredModalTree
          show={modalShowTree}
          onHide={() => setModalShowTree(false)}
        />
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModalTree(props) {

  const history = useHistory();
  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const authContext = useContext(AuthContext);
  const { mensaje, autenticado, registrarCliente } = authContext;

  useEffect(() => {
    if (autenticado) {
      history.push('/catalog');
    }

    if (mensaje) {
      mostrarAlerta(mensaje.msg, "cat");
    }
  }, [mensaje, autenticado, props.history]
  )

  const [usuario, guardarUsuario] = useState({
    negocio: '',
    cuit: '',
    nombre: '',
    apellido: '',
    telefono: '',
    mail: '',
    password: ''
  });

  const { negocio, cuit, nombre, apellido, telefono, mail, password } = usuario;

  const onChange = e => {
    guardarUsuario({
      ...usuario,
      [e.target.name]: e.target.value
    })
  }

  const onSubmit = e => {
    e.preventDefault();
    registrarCliente({
      nombre, apellido, telefono, mail, password, negocio, cuit
    });
  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>

      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">Registrarse</h4>
        <Form onSubmit={onSubmit}>
          <br></br>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="negocio"  >
              <Form.Label>Negocio / R. Social </Form.Label>
              <Form.Control type="text" placeholder="Ingrese Negocio / R. Social" name="negocio" value={negocio} onChange={onChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="cuit">
              <Form.Label>CUIT/CUIL</Form.Label>
              <Form.Control type="text" placeholder="XX-XXXXXXXX-X" name="cuit" value={cuit} onChange={onChange} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" placeholder="Nombre" name="nombre" value={nombre} onChange={onChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="apellido">
              <Form.Label>Apellido</Form.Label>
              <Form.Control type="text" placeholder="Apellido" name="apellido" value={apellido} onChange={onChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="telefono">
              <Form.Label>Telefono</Form.Label>
              <Form.Control type="text" placeholder="Telefono" name="telefono" value={telefono} onChange={onChange} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="mail">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control type="email" placeholder="Ingrese Correo Electrónico" name="mail" value={mail} onChange={onChange} />
            </Form.Group>
            <Form.Group as={Col} controlId="password">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control type="password" placeholder="Ingrese Contraseña" name="password" value={password} onChange={onChange} />
            </Form.Group>
          </Row>


          {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
          <Row className="mb-3" style={{ justifyContent: "center", textAlign: "center" }}>
            <Button variant="primary" type="submit" >
              Registrarse
            </Button>
          </Row>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={props.onHide}>Iniciar Sesión</Button>
      </Modal.Footer>
    </Modal>
  );
}




function MyVerticallyCenteredModalTwo(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>

      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">Nosotros</h4>
        <p>
          <p className="text-justify">Distribuidora Juan.I se inició en 2016. Es una empresa familiar fundada por Nelson Ingoglia, dedicada a la venta de insumos, accesorios, cosmética del rubro automotor.</p>
          <p className="text-justify">Nuestro objetivo es a través de calidad, precio e innovación, brindar a nuestros clientes el mejor de los servicios. </p>
          <p className="text-justify">Buscamos cumplir ampliamente todas sus expectativas, construyendo relaciones mutuamente beneficiosas y perdurable, con total compromiso y responsabilidad.</p>
          <p className="text-center"> Agradecemos su visita a nuestra página deseando continuar este contacto.</p>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={props.onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
}



