import React, { useState } from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Delete from '../../assets/img/delete.png';
import clienteAxios from "../../config/axios";

const Gallery = (props) => {

  const ImgData = props.data;
  const llamado = props.llamado;
  // carusel data component

  const handleDeleteChange = async selected => {
    console.log(selected.target.name);
    try {

      const formData = new FormData();
      formData.append('file', selected.target.name);
      if (llamado === "marcs") {
        let resultado = await clienteAxios.post('/library/delMarcs',formData);
       
      }
      if (llamado === "products") {
        let resultado = await clienteAxios.post('/library/delProducts',formData);
        
      }
      if (llamado === "carrousel") {
        let resultado = await clienteAxios.post('/library/delCarrousel',formData);
        
      }
      window.location.reload(true);
    } catch (err) {

    }
}

function ImgComponent({ src, alt, classId, duracion }) {
  return (
    <ListGroup.Item variant="light" style={{ width: '150px', margin: '5px 5px 5px 5px', border: '2px' }} >
      <div style={{ textAlign: 'end' }}>
        <a href={"#" + classId} onClick={handleDeleteChange} id={classId} ><img src={Delete} alt={Delete} style={{ width: '20px' }} id={classId} name={alt} /></a>
      </div>
      <div>
        <img src={src} alt={src} style={{ width: '100px' }} />
      </div>
      <div>
        <span style={{ fontSize: '12px' }} >{alt}</span>
      </div>
    </ListGroup.Item>
  );
}

return (
  ImgData.map((element) => (
    <ImgComponent src={element.src} alt={element.alt} classId={element.classId} />
  ))
)
};

export default Gallery;
