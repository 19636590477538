import React, { Component, useState } from 'react'
import { Dropdown, FormControl, Card } from 'react-bootstrap';
import '../../assets/css/indexDropdown.css';
import imgBuscador from '../../assets/img/buscador.png';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href="#a"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="testeo"
    >
        {children}
        &#x25bc;
    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Categoria"
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);

export class CategoryDropdown extends Component {


    render() {
        return (

            <Card 
                bg="light"
                text={"light" === 'light' ? 'dark' : 'white'}
                className="col-sm-12 col-md-12 item shadow bg-body rounded" data-aos="fade-right">
                <Card.Body >
                    <h3 className="text-center">Categorias</h3>
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-2" ></div>
                        <div className="col-lg-3 col-md-3 col-sm-3" >
                            <img src={imgBuscador} alt="buscar" style={{ width: "100%" }}></img>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-7" style={{ textAlign: "initial" }} >
                            <br></br>
                            <Dropdown >
                                <h5 ><Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                    Seleccione
                                </Dropdown.Toggle>
                                </h5>
                                <Dropdown.Menu as={CustomMenu} className="dropdown-scrollbar" >
                                    <Dropdown.Item href="/catalog?cat=Cat|A" eventKey="1">Cat A</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">Cat B</Dropdown.Item>
                                    <Dropdown.Item eventKey="3">Cat C</Dropdown.Item>
                                    <Dropdown.Item eventKey="4">Categoria D</Dropdown.Item>
                                    <Dropdown.Item eventKey="5">Categoria E</Dropdown.Item>
                                    <Dropdown.Item eventKey="6">Categoria F</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Card.Body>
            </Card>


        )
    }
}



export default CategoryDropdown



